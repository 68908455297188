<template>
  	<div class="form-group">
        <label v-bind:for="inputName">{{ transLabel }}</label>
        <span></span>
        <input type="text" v-bind:name="inputName" v-bind:placeholder="transPlaceholder" v-model="inputValue" @change="onChange">
        <p v-show="error" class="alert error">{{ transErrorMessage }}</p>
    </div>
</template>

<script>
export default {
  name: "text-input",
  props: ["label", "inputName", "placeholder", "storeName"],
  data() {
    return {
      inputValue: "",
      error: false,
      errorMessage: "",
      //Translatables
      transLabel: "",
      transPlaceholder: "",
      transErrorMessage: ""
    };
  },
  created(){
    let session = this.$store.getters.getBookingSession;
    this.inputValue = session[this.$props.inputName];
  },
  mounted() {
    this.updateLocale();
    this.$root.$on("change-locale", () => {
      this.updateLocale();
    });
    this.$root.$on("switch-tab", () => {
      let session = this.$store.getters.getBookingSession;
      this.inputValue = session[this.$props.inputName];
    });
  },
  methods: {
    updateLocale(){
      this.transLabel = this.$gettext(this.$props.label);
      let translatedPlaceholder = this.$gettext(this.$props.placeholder);
      this.transPlaceholder = this.$gettextInterpolate(translatedPlaceholder, {field: this.transLabel.toLowerCase()});
      this.transErrorMessage = this.$gettext(this.errorMessage);
    },
    setError(error){
      this.error = error;
    },
    setErrorMessage(message){
      this.errorMessage = message;
      this.updateLocale();
    },
    onChange: function() {
      this.$store.commit(this.$props.storeName, {
        value: this.inputValue
      });
      this.$emit("onChange");
    }
  }
};
</script>
