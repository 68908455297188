<template>
  	<div class="form-group">
        <label for="nothing">{{ transLabel }} <Loader v-show="fetchingHours" /></label>
        <select ref="hourSelect" :name="nameHours" :class="classList(nameHours)" :id="nameHours" required :value="hours" @change="onHourChange">
            <option disabled value>{{ transPlaceholderHour }}</option>
            <option v-for="n in 24" v-bind:value="getHours(n-1)" v-bind:disabled="getDisabled(n-1) == 1">{{ getHours(n-1) }}</option>
        </select>
        <select ref="minuteSelect" :name="nameMinutes" :class="classList(nameMinutes)" :id="nameMinutes" required :value="minutes" @change="onMinuteChange">
            <option disabled value>{{ transPlaceholderMinute }}</option>
            <option v-for="n in 12" v-bind:value="getMinutes(n-1)" >{{ getMinutes(n-1) }}</option>
        </select>
    </div>
</template>

<script>
import Loader from "@/components/formComponents/Loader";

export default {
  props: [
    "label",
    "nameHours",
    "nameMinutes",
    "placeholderHours",
    "placeholderMinutes"
  ],
  components: {
    Loader
  },
  data() {
    return {
      //Translatables
      transLabel: "",
      transPlaceholderHour: "",
      transPlaceholderMinute: ""
    };
  },
  computed: {
    closingHours(){
      return this.$store.state.api.closingHours;
    },
    fetchingHours(){
      return this.$store.state.api.fetchingHours;
    },
    hours(){
      return this.$store.state.booking.hours;
    },
    minutes(){
      return this.$store.state.booking.minutes;
    }
  },
  mounted() {
    this.updateLocale();
    this.$root.$on("change-locale", () => {
      this.updateLocale();
    });
  },
  methods: {
    classList(classNames) {
      let errors = ["InvalidDateError", "PreCheckTimeTooShortTermError"];
      let classes = {};
      classes["time"] = true;
      classes[classNames] = true;
      classes["error"] = (this.$store.state.booking.disabledClick && this.$store.state.booking.hours === "" && classNames === "time-hour")
      if(this.$store.state.error.active && errors.includes(this.$store.state.error.errorKey)){
        classes["error"] = true;
      }
      return classes;
    },
    updateLocale(){
      this.transLabel = this.$gettext(this.$props.label);
      this.transPlaceholderHour = this.$gettext(this.$props.placeholderHours);
      this.transPlaceholderMinute = this.$gettext(this.$props.placeholderMinutes);
    },
    getHours(x) {
      if (x > 9) {
        return x;
      } else {
        return "0" + x;
      }
    },
    getMinutes(x) {
      if (x * 5 > 9) {
        return x * 5;
      } else {
        return "0" + x * 5;
      }
    },
    getDisabled(x) {
      if (this.closingHours.includes(parseInt(x))) {
        return 1;
      } else {
        return 0;
      }
    },
    onHourChange(){
      this.$store.commit("setBookingHours", event.target.value);
      this.$emit("onChange");
    },
    onMinuteChange: function(value) {
      this.$store.commit("setBookingMinutes", event.target.value);
      this.$emit("onChange");
    }
  }
};
</script>
