<template>
  	<div class="form-group">
        <label v-bind:for="name">{{ transLabel }}</label>
        <span v-show="label == 'childrenC'">{{ transSubChildren }}</span>
        <input :class="getClasses()" type="number" v-bind:name="name" v-bind:placeholder="transPlaceholder" min="0" max="50" step="1" pattern="\d*" v-model="inputValue" @change="onChange">
    </div>
</template>

<script>
export default {
  name: "number-input",
  props: ["label", "name", "placeholder"],
  data() {
    return {
      inputValue: "",
      //Translatables
      transLabel: "",
      transPlaceholder: "",
      transSubChildren: ""
    };
  },
  created(){
    let booking = this.$store.getters.getBooking;
    this.inputValue = booking[this.$props.name];
  },
  mounted() {
    this.updateLocale();
    this.$root.$on("change-locale", () => {
      this.updateLocale();
    }); 
    this.$root.$on("switch-tab", () => {
      let booking = this.$store.getters.getBooking;
      this.inputValue = booking[this.$props.name];
    });
  },
  methods: {
    getClasses(){
      let errorList = ["pax", "adults"]
      return {
        "error": (this.$store.state.booking.disabledClick && this.$store.state.booking[this.$props.name] === "" && errorList.includes(this.$props.name))
      };
    },
    updateLocale(){
      this.transLabel = this.$gettext(this.$props.label);
      this.transPlaceholder = this.$gettext(this.$props.placeholder);
      this.transSubChildren = this.$gettext("childAge");
    },
    onChange: function() {
      this.$store.commit("setPAX", {
        type: this.name,
        number: this.inputValue
      });
      this.$emit("onChange");
    }
  }
};
</script>
