<template>
  <div>
	
	<div class="row row-eq-height">
    <!-- Change translation! DEV ONLY 
    <div class="test-buttons">
      <button @click="changeLanguage('en_US')">English</button>
      <button @click="changeLanguage('nl_NL')">Dutch</button>
    </div> -->

    <!-- Left view - Form -->
    <div class="col-xs-12 col-sm-6 col-lg-5">
      <BookingForm v-on:update-booking-view="updateView"></BookingForm>
    </div>

    <!-- Right view - Contact -->
    <div class="col-xs-12 col-sm-6 col-lg-5">
      <ContactForm 
        ref="contactForm"
        v-on:close-booking-view="closeContactForm" 
        v-on:update-booking-view="updateView" 
        v-show="showContactForm && confirmationPossible"></ContactForm>
    </div>
  </div>

  </div>
</template>

<script>
import BookingForm from "@/components/BookingForm";
import ContactForm from "@/components/ContactForm";
import FormUtils from "@/components/utilComponents/FormUtils";
import Vue from 'vue'
export default {
  components: {
    BookingForm,
    ContactForm
  },
  mixins: [FormUtils],
  data: function() {
    return {
      showContactForm: false
    };
  },
  computed: {
    confirmationPossible(){
      return this.$store.state.bookingSession.confirmationPossible;
    }
  },
  created: function(){
    this.updateView();
  },
  methods: {
    changeLanguage(lan){
      Vue.config.language = lan;
      this.$root.$emit("change-locale");
    },
    closeContactForm(){
      this.showContactForm = false;
    },
    updateView(){
      var session = this.$store.getters.getBookingSession;
      this.$root.$emit("refresh-contact-form");
      if(session.valid){
        this.$ga.event({
          eventCategory: 'ReservationConfirmation',
          eventAction: "navigate",
          eventLabel: "User is on contact form"
        });
        this.$store.commit("setAnalyticsPageStep", 3);
        this.showContactForm = true;
      }else{
        this.$store.commit("setAnalyticsPageStep", 2);
        this.showContactForm = false;
      }
    }
  }
};
</script>