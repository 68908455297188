var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"close-container hidden-sm hidden-md hidden-lg",attrs:{"id":"mapCloseContainer"}},[_c('div',{staticClass:"img back-icn"}),_vm._v("\n\t\t\t"+_vm._s(_vm.transBack)+"\n\t\t\t\n\t")]),_vm._m(0),_c('div',{staticClass:"search-container",attrs:{"id":"mapSearchContainer"}},[_c('button',{staticClass:"back",on:{"click":function($event){return _vm.closeMaps()}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],ref:"google-autocomplete",staticClass:"No-doc-click",attrs:{"type":"text","id":"mapSearch","placeholder":_vm.transSearchPlaceholder},domProps:{"value":(_vm.search)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchPlaces()},"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}}),_c('button',{staticClass:"search-places",on:{"click":function($event){return _vm.searchPlaces()}}}),_c('button',{staticClass:"location",on:{"click":function($event){return _vm.getUserLocation()}}}),(_vm.currentError !== '')?_c('div',{staticClass:"c-error_no-location-access"},[_c('button',{staticClass:"close-button",on:{"click":function($event){return _vm.resetError()}}}),_c('p',[_vm._v(_vm._s(_vm.transLocationError))])]):_vm._e()]),_vm._m(1),_c('gmap-map',{ref:"googlemaps",attrs:{"id":"map","center":_vm.center,"zoom":13,"options":{
			disableDefaultUI: true,
			zoomControl: true,
			clickableIcons: true,
			gestureHandling: 'cooperative'
		}}},_vm._l((_vm.locations),function(location,index){return _c('gmap-marker',{key:location.id,attrs:{"position":{lat: location.latitude, lng: location.longitude},"icon":location.icon,"zone":location.zone,"jettyNumber":location.jettyNumber,"selected":location.selected,"zIndex":location.zIndex,"label":{
				text: location.jettyNumber.toString(),
				color: '#fff',
                fontSize: '13px',
                fontFamily: 'din_round_otbold'
			}},on:{"mouseover":function($event){return _vm.mouseOverMarker(index)},"mouseout":function($event){return _vm.mouseOutMarker(index)},"click":function($event){return _vm.openInfoWindow(index)}}},[_c('gmap-info-window',{attrs:{"opened":location.infoBoxOpen}},[_c('div',{staticClass:"infoWindow",staticStyle:{"width":"300px"}},[_c('div',{class:_vm.getZoneClass(location.zone),attrs:{"id":"dock-zone"}},[_vm._v(_vm._s(_vm.getZoneClassText(location.zone)))]),_c('h2',{attrs:{"id":"infoWindow-location"}},[_vm._v(_vm._s(location.name))]),_c('div',{attrs:{"id":"close-btn"},on:{"click":function($event){return _vm.closeInfoWindow()}}}),_c('a',{staticClass:"btn btn-primary google-maps-infowindow-button",on:{"click":function($event){return _vm.selectDock(location)}}},[_vm._v(_vm._s(_vm.transInfoWindowButton)+" "),_c('b',[_vm._v(_vm._s(_vm.setInfoBoxButtonLabel(_vm.selectionData.inputData)))])]),_c('span')])])],1)}),1),_c('div',{staticClass:"clearfix"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"close-container hidden-sm hidden-md hidden-lg",attrs:{"id":"mapTitleContainer"}},[_c('h2')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"legend"},[_c('h3',[_vm._v("Zones")]),_c('img',{attrs:{"src":"/images/legend.svg","width":"84","height":"23"}})])}]

export { render, staticRenderFns }