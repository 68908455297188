<template>
  <div class="form-group">
    <label for="date">{{ transLabel }}</label>
    <flat-pickr
      :value="dateValue"
      :config="config"
      :events="events"
      :class="getClasses()"
      :placeholder="transPlaceholder"
      autocomplete="off"
      :name="name"
      @on-change="onChangeDate"
      @on-day-create="onDayCreate"
    ></flat-pickr>
  </div>
</template>

<script>
import HttpRequests from "@/components/utilComponents/HttpRequests";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import { Dutch } from "flatpickr/dist/l10n/nl.js";
import 'flatpickr/dist/flatpickr.css';

export default {
  mixins: [HttpRequests],
  components: {
    flatPickr
  },
  props: ["label", "name", "bookingType", "placeholder"],
  computed: {
    dateValue(){
      return this.$store.state.booking.dateTime;
    }
  },
  mounted() {
    this.updateLocale();
    this.$root.$on("change-locale", () => {
      this.updateLocale();
    });
  },
  data() {
    return {
      // Get more form https://chmln.github.io/flatpickr/options/
      config: {
        dateFormat: "d/m/Y",
        minDate: moment().format("DD/MM/YYYY"), // Today
        altFormat: "YYYYMMDD",
        allowInput: true,
        locale: Dutch // locale for this instance only
      },
      // Allowed events
      events: ["onDayCreate", "onChange"],
      //Translatables
      transLabel: "",
      transPlaceholder: ""
    };
  },
  methods: {
    getClasses(){
      let classes = {};
      classes[this.bookingType + " flatpickr-input datepicker"] = true;
      classes["error"] = (this.$store.state.booking.disabledClick && this.$store.state.booking.dateTime === null)
      return classes
    },
    updateLocale(){
      this.transLabel = this.$gettext(this.$props.label);
      this.transPlaceholder = this.$gettext(this.$props.placeholder);
    },
    onChangeDate(selectedDates, dateStr, instance) {
      if(!this.$store.state.api.fetchingHours){
        this.getOpeningHours(moment(selectedDates[0].toISOString())).then(response => {
            let dates = response.data;
            let beforeDay = dates[0];
            let selectedDay = dates[1];

            // Day before
            let minimumClosing = 0;
            if(!beforeDay.closedForTheDay){
              let pastClosingTime = moment.utc(beforeDay.closingDate).tz("Europe/Amsterdam")
              if(pastClosingTime.hour() > 0 && pastClosingTime.hour() < 5){
                minimumClosing = pastClosingTime.hour()
              }
            }
            
            // Selected day
            let closingHours = [];
            if(selectedDay.closedForTheDay){
              for(let i = 0; i <= 23; i++){
                closingHours.push(i);
              }        
            }else{
              let opendingDate = moment.utc(selectedDay.openingDate).tz("Europe/Amsterdam")
              let closingDate = moment.utc(selectedDay.closingDate).tz("Europe/Amsterdam")

              // Normal hours
              for(let i = minimumClosing; i < opendingDate.hour(); i++){
                closingHours.push(i);
              }

              // End closing hours
              if(closingDate.hour() > opendingDate.hour()){
                for(let i = 23; i > closingDate.hour()-1; i--){
                  closingHours.push(i);
                }
              }
            }

            this.$store.commit("updateState", {
              key: "api",
              type: "closingHours",
              body: closingHours
            });

            this.$store.commit("setClosedForTheDay", selectedDay.closedForTheDay);

            setTimeout(() => {
              this.$store.commit("setFetchingHours", false);
            }, 500);

            this.$store.commit("setDate", {
              dateTime: dateStr
            });
            this.$emit("onChange");
        });        
      }
    },
    onDayCreate(dObj, dStr, fp, dayElem){
      if(dayElem.className.includes("nextMonthDay") || dayElem.className.includes("prevMonthDay")){
        dayElem.style.opacity = 0
        dayElem.className = `${dayElem.className} disabled`
      }
    }
  }
};
</script>

<style lang="scss">
$selected-color: #f5cf00;
$today-color: #f1f1f1;
$today-color-hover: #3c3b32;
$disabled-color: #c6c6c6;

.flatpickr-current-month{
 .flatpickr-monthDropdown-months{
  appearance: none;
    -webkit-appearance: none;
  }
}

.dayContainer{

  .today{
    background-color: $today-color;
    border-color: $today-color;
  }

  .flatpickr-disabled{
    color: $disabled-color;
    &:hover{
      color: $disabled-color;
    }
  }

  .flatpickr-day{
    border-radius: 0;
  }

  .selected{
    background-color: $selected-color;
    border-color: $selected-color;
    color: $today-color-hover;

    &:hover{
      background-color: $selected-color;
      border-color: $selected-color;
      color: $today-color-hover;
    }
  }
}

</style>
