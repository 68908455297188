<template>
  <div
    @keyup="listNavigation"
    class="form-group autocomplete-group"
    v-bind:class="{ 'stop-entered': true && !openAutocomplete && inputValue != '', 'active-group': openAutocomplete }"
  >
    <label :for="inputName">{{ transLabel }}</label>
    <input
      @focus="focusInput()"
      @blur="blurInput()"
      @keyup="searchLocations"
      v-model="inputValue"
      type="text"
      :placeholder="transPlaceholder"
      :class="getClasses()"
      autocomplete="off"
    >

    <div v-on:click="openMaps(inputData, 'icon')" class="icon-map">
      <img>
    </div>
    <div
      @mouseover="isHoveringAutocomplete()"
      @mouseout="notHoveringAutocomplete()"
      class="auto-complete-container"
      v-show="openAutocomplete"
    >
      <div class="scroll-list">
        <ul v-if="(this.filteredLocations.length != 0)">
          <li
            :ref='"dock-item-" + location.id'
            :class='{"selected":currentItemId == location.id}'
            v-for='location in this.filteredLocations'
            v-on:click='selectLocation(location)'
          >
            <div class="icon"></div>
            <div class="text">
              <h3 v-html="highlightSearch(location)"></h3>
            </div>
          </li>
        </ul>

        <div v-if="(this.filteredLocations.length == 0)" class="no-dock-found">
          <h3>{{ transDockNotFound }}</h3>
          <p>{{ transDockNotFoundInfo }}</p>
        </div>
      </div>
      <a v-on:click="openMaps(inputData, 'dropdown')" class="btn btn-primary">{{ transSearchDock }}</a>
    </div>
  </div>
</template>

<script>
import FormUtils from "@/components/utilComponents/HttpRequests";
import "jquery.scrollto";
import { toRegex } from 'diacritic-regex';

export default {
  props: ["inputData", "label", "inputName"],
  data: function() {
    return {
      currentItemId: 0,
      isHovering: false,
      openAutocomplete: false,
      inputValue: "",
      searchValue: "",
      filteredLocations: [],
      //Translatables
      transLabel: "",
      transPlaceholder: "",
      transSearchDock: "",
      transDockNotFound: "",
      transDockNotFoundInfo: ""
    };
  },
  created(){
    let booking = this.$store.getters.getBooking;
    this.inputValue = booking[this.$props.inputName].name;
    this.currentItemId = booking[this.$props.inputName].id;
  },
  mounted() {

    this.updateLocale();

    this.$root.$on("select-dock", data => {
      if (data.inputData.field == this.$props.inputName) {
        this.inputValue = data.dock.name;
        this.searchValue = data.dock.name;
        this.$store.commit("changeLocation", {
          type: data.inputData.field,
          location: data.dock
        });
        this.$emit("onChange");
      }
    });
    this.$root.$on("switch-tab", () => {
      let booking = this.$store.getters.getBooking;
      this.inputValue = booking[this.$props.inputName].name;
      this.currentItemId = booking[this.$props.inputName].id;
    });

    this.$root.$on("change-locale", () => {
      this.updateLocale();
    });    
  },
  methods: {
    getClasses(){
      return {
        "map-enabled": true,
        "error": (this.$store.state.booking.disabledClick && this.$store.state.booking[this.$props.inputName].id === 0)
      }
    },
    updateLocale(){
      this.transLabel = this.$gettext(this.$props.label);
      this.transPlaceholder = this.$gettext("locationPlaceholder");
      this.transSearchDock = this.$gettext("findDockOnMap");
      this.transDockNotFound = this.$gettext("noDockFound");
      this.transDockNotFoundInfo = this.$gettext("noDockFoundInput");
    },
    listNavigation() {
      let pos = this.filteredLocations
        .map(function(l) {
          return l.id;
        })
        .indexOf(this.currentItemId);

      switch (event.keyCode) {
        // Up
        case 38:
          pos--;
          if (pos < 0) {
            pos = this.filteredLocations.length - 1;
          }
          this.currentItemId = this.filteredLocations[pos].id;
          this.scrollToElement(pos);
          break;
        // Down
        case 40:
          pos++;
          if (pos >= this.filteredLocations.length) {
            pos = 0;
          }
          this.currentItemId = this.filteredLocations[pos].id;
          this.scrollToElement(pos);
          break;
        // Enter
        case 13:
          this.selectLocation(this.filteredLocations[pos]);
          $("input").blur();
          break;
      }
    },
    scrollToElement(pos) {
      // Scroll to selected element
      for(let [key, value] of Object.entries(this.$refs)){
        if(key == "dock-item-"+this.filteredLocations[pos].id){
          if(value[0]){
              $(value[0]).parent().parent().scrollTo(value[0]);
              break;
          }
        }
      }
    },
    openMaps(inputData, userAction) {

      this.$ga.event({
        eventCategory: 'DockSearch',
        eventAction: "click",
        eventLabel: 'Search docks by ' + userAction
      });

      this.$emit("open-maps", {
        inputData: inputData,
        search: this.searchValue
      });
      let booking = this.$store.getters.getBooking;
      this.inputValue = booking[this.$props.inputName].name;
      this.openAutocomplete = false;
    },
    filterSelectedLocations(){
      let locations = this.$store.getters.getApiLocations;
      let takenLocation = {};
      switch(this.$props.inputName){
        case "departureLocation": takenLocation = this.$store.getters.getlocation("arrivalLocation"); break;
        case "arrivalLocation": takenLocation = this.$store.getters.getlocation("departureLocation"); break;
      }

      if(takenLocation.name != ""){
        locations = locations.filter(l => l.id != takenLocation.id);
      }
      return locations;
    },
    focusInput() {
      // One time check per component
      if (this.currentItemId == 0) {
        this.currentItemId = this.$store.getters.getApiLocations[0].id;
      }
      this.filteredLocations = this.filterSelectedLocations();
      this.openAutocomplete = true;
      this.inputValue = "";
    },
    blurInput() {
      if (!this.isHovering) {
        this.openAutocomplete = false;
        var location = this.$store.getters.getlocation(this.$props.inputName);
        this.inputValue = location.name;
      }
    },
    isHoveringAutocomplete() {
      this.isHovering = true;
    },
    notHoveringAutocomplete() {
      this.isHovering = false;
    },
    searchLocations() {
      this.searchValue = this.inputValue;
      this.filteredLocations = this.filterSelectedLocations().filter(
        locationFilter(this.inputValue)
      );
    },
    highlightSearch(location) {
      let query = this.searchValue.trim();
      let name = location.name;
      let dockNr = location.jettyNumber.toString();
      let regEx = RegExp(toRegex()(query), "ig");
      name = name.replace(regEx, "<span>$&</span>");
      dockNr = dockNr.replace(regEx, "<span>$&</span>");
      return name + " - " + dockNr;
    },
    selectLocation(location) {
      this.searchValue = location.name;
      this.inputValue = location.name;
      this.$store.commit("changeLocation", {
        type: this.$props.inputName,
        location: location
      });
      this.openAutocomplete = false;
      this.$emit("onChange");
    }
  }
};

function locationFilter(query) {
  return function(location) {
    let whitelist = ["0", "01"];
    query = query.trim();
    var firstChar = query.charAt(0);
    var field = "";
    if (isNaN(firstChar) || whitelist.includes(firstChar)) {
      // Normalize to separate all letters from their accents and replace all of the special 
      // characters between the range of u0300 and u036f with nothing. 
      // See https://unicode-table.com/en/ for the full table of unicodes!
      field = location.name
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    } else {
      field = location.jettyNumber.toString();
    }

    var result = field.search(query.toLowerCase());
    if (result != -1) {
      return true;
    } else {
      return false;
    }
  };
}
</script>
