<template>
  	<div class="form-group">
        <label v-bind:for="inputName">{{ transLabel }}</label>
        <select :name="inputName" :class="getClasses()" :id="inputName" required v-model="selected" @change="onChange">
            <option disabled value>{{ transPlaceholder }}</option>
            <option v-for="option in options" v-bind:value="option.value">{{ option.name }}</option>
        </select>
    </div>
</template>

<script>
export default {
  name: "select-input",
  props: ["label", "inputName", "placeholder", "storeName", "options"],
  data() {
    return {
      selected: "",
      //Translatables
      transLabel: "",
      transPlaceholder: ""
    };
  },
  created(){
    let booking = this.$store.getters.getBooking;
    this.selected = booking[this.$props.inputName];
  },
  mounted() {
    this.updateLocale();
    this.$root.$on("change-locale", () => {
      this.updateLocale();
    });
    this.$root.$on("switch-tab", () => {
      let booking = this.$store.getters.getBooking;
      this.selected = booking[this.$props.inputName];
    });
  },
  methods: {
    getClasses(){
      return {
        "duration": true,
        "error": (this.$store.state.booking.disabledClick && this.$store.state.booking.duration === "" && this.$props.inputName === "duration")
      };
    },
    updateLocale(){
      this.transLabel = this.$gettext(this.$props.label);
      this.transPlaceholder = this.$gettext(this.$props.placeholder);
    },
    onChange: function() {
      this.$store.commit(this.$props.storeName, {
        value: this.selected
      });
      this.$emit("onChange");
    }
  }
};
</script>
