import axios from "axios"
import moment from "moment-timezone";
import Vue from "vue";

const Axios = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});

export default {

  methods: {
    // Getters
    getLocations() {
      if (this.$store.getters.getApiLocations.length == 0) {
        Axios.get("/locations/list").then(response => {
          this.$store.commit("updateState", {
            key: "api",
            type: "locations",
            body: response.data
          });
        }).catch(error => {
          console.log("Error fetching locations");
          console.log(error);
        });
      }
    },

    getPrice() {
      Axios.get(this.returnPriceUrl()).then(response => {
        this.$store.commit("updateState", {
          key: "booking",
          type: "price",
          body: response.data
        });
      }).catch(error => {
        console.log("Error fetching price");
        console.log(error);
      });
    },

    getOpeningHours(date){
      this.$store.commit("setFetchingHours", true);
      let pastDate = moment(date).subtract(1, 'days')
      return Axios.get("/openinghours", {
        params: {
          fromDate: pastDate.format("YYYY-MM-DDT22:00:00"), 
          untilDate: date.format("YYYY-MM-DDT22:00:00")
        }
      });
    },

    returnPriceUrl() {
      var url = "";
      var booking = this.$store.getters.getBooking;
      // if child is empty, send 0
      var children = booking.children;
      if(children == ""){
          children = 0;
          this.$store.commit("setPAX", {type: "children", number: 0});
      }
      // if minutes is empty, send 0
      if(booking.minutes == ""){
          this.$store.commit("setBookingMinutes", "00");
      }

      switch(booking.bookingType){
        case "TAXI_RIDE": 
          url = "/price/taxi-ride?date=" +
            this.$store.getters.getformatteddate +
            "&startLocationId=" +
            booking.departureLocation.id +
            "&endLocationId=" +
            booking.arrivalLocation.id +
            "&paxAdult=" +
            booking.adults +
            "&paxChild=" +
            children;
        break;
        case "CRUISE": 
          url = "/price/cruise?date=" +
            this.$store.getters.getformatteddate +
            "&pax=" +
            booking.pax +
            "&duration=" +
            booking.duration +
            "&boatTypeId=" +
            booking.boatType;
        break;
      }
      return url;
    },

    async sameDayValidation(type, dateTime){
      return Axios.get("/booking/validate", {params: {"type": type, "date": dateTime}})
    },

    // Posts
    createBooking() {
      function getLanguage() {
        switch (Vue.config.language) {
          case 'nl_NL': return 'NL';
          case 'en_US': return 'EN';
        }
      }

      let bookingType = this.$store.getters.getbookingtype;
      let postData = {};
      switch(bookingType){
        case "TAXI_RIDE": 
          postData = {
            type: this.$store.getters.getbookingtype,
            date: this.$store.getters.getformatteddate,
            startLocationId: this.$store.getters.getlocation("departureLocation").id,
            endLocationId: this.$store.getters.getlocation("arrivalLocation").id,
            paxAdult: this.$store.getters.getPAX("adults"),
            paxChild: this.$store.getters.getPAX("children"),
            oneHousehold: this.$store.getters.getHousehold,
            language: getLanguage()
          };
        break;
        case "CRUISE": 
          postData = {
            type: this.$store.getters.getbookingtype,
            date: this.$store.getters.getformatteddate,
            startLocationId: this.$store.getters.getlocation("departureLocation").id,
            endLocationId: this.$store.getters.getlocation("arrivalLocation").id,
            pax: this.$store.getters.getPAX("passengers"),
            duration: this.$store.getters.getDuration,
            boatTypeId: this.$store.getters.getBoatType,
            oneHousehold: this.$store.getters.getHousehold,
            language: getLanguage()
          };
        break; 
      }
      return Axios.post("/booking", postData);
    },

    confirmBooking() {
      let session = this.$store.getters.getBookingSession;
      let postData = {
        agreedToTermsConditions: session.agreedToTermsConditions,
        firstName: session.firstName,
        lastName: session.lastName,
        email: session.email,
        phone: session.phone
      };
      let url = "/booking/" + session.id + "/confirm/?sessionKey=" + session.sessionKey
      return Axios.put(url, postData)
    },

    retrieveBooking(callback) {
      let session = this.$store.getters.getBookingSession;
      let postData = {
        agreedToTermsConditions: session.agreedToTermsConditions,
        firstName: session.firstName,
        lastName: session.lastName,
        email: session.email,
        phone: session.phone
      };
      let url = "/booking/" + session.id + "/reclaim-time-slot/?sessionKey=" + session.sessionKey
      return Axios.put(url, postData);
    },

    cancelBooking() {
      let session = this.$store.getters.getBookingSession;
      let url = "/booking/" + session.id + "?sessionKey=" + session.sessionKey
      return Axios.delete(url);
    }

  }
};
