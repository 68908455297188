<template>
  <!-- Box Form -->
  <div class="form-content" rel="taxi-rit">
    <!-- Box Form - Docks -->
    <div class="row">
      <div class="col-xs-12">
        <DockSelectionInput
          label="departureLocationC"
          inputName="departureLocation"
          :inputData="departureData"
          v-on:open-maps="openMaps"
          @onChange="updateLocation"
        ></DockSelectionInput>

        <DockSelectionInput
          label="arrivalLocationC"
          inputName="arrivalLocation"
          :inputData="arrivalData"
          v-on:open-maps="openMaps"
          @onChange="updateLocation"
        ></DockSelectionInput>
      </div>
    </div>

    <!-- Box Form - Date and Time -->
    <div class="row">
      <div class="col-xs-6 diff-gutter">
        <DateInput
          label="date"
          name="date"
          bookingType="Taxirit"
          placeholder="datePlaceholder"
          @onChange="updateDate"
        ></DateInput>
      </div>
      <div class="col-xs-6 diff-gutter">
        <TimeInput
          label="time"
          nameHours="time-hour"
          nameMinutes="time-minutes"
          placeholderHours="hour"
          placeholderMinutes="min"
          @onChange="updateTime"
        ></TimeInput>
      </div>
      <ReservationError :showError="!enableBtn" />
    </div>

    <!-- Box Form - People -->
    <div class="row">
      <div class="col-xs-6 diff-gutter">
        <NumberInput
          label="adultsC"
          name="adults"
          placeholder="insertNumber"
          @onChange="updateNumber"
        ></NumberInput>
      </div>
      <div class="col-xs-6 diff-gutter">
        <NumberInput
          label="childrenC"
          name="children"
          placeholder="insertNumber"
          @onChange="updateNumber"
        ></NumberInput>
      </div>
    </div>

    <!-- Household Check -->
    <!--<div class="row">
      <div class="col-xs-12 diff-gutter">
        <div class="form-group">
            <div class="form-box">
          <label>
            {{transHousehold}}
          </label>
          <div class="row radio-group center" :class="{'alert-text': getHouseHoldValidation}">
            <div class="col-xs-12 radio-box">
              <input @click="updateHousehold('ishousehold')" id="ishouseholdTaxi" type="radio" :value="isHousehold" v-model="household" name="householdCheckTaxi">
              <label class="label-small" for="ishouseholdTaxi">
                {{transHasHousehold}}     
              </label>
            </div>
            <div class="col-xs-12 radio-box">
              <div class="label-small">
              <input  @click="updateHousehold('nohousehold')" id="nohouseholdTaxi" type="radio" :value="isNotHousehold" v-model="household" name="householdCheckTaxi">
              <label class="label-small" for="nohouseholdTaxi">
                  {{transHasNoHousehold}}
              </label>
            </div>              
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>-->

    <!-- Box Form - Price -->
    <p v-show="enableBtn" class="price">
      {{ this.transPrice }}
      <span class="bold din">{{
        this.formatPrice(this.$store.getters.getSessionedPrice)
      }}</span>
    </p>

    <!-- Box Form - Submit -->
    <input
      type="submit"
      :class="submitStateClasses"
      :value="transSubmit"
      @click="toBooking()"
    />
  </div>
</template>

<script>
import DockSelectionInput from "@/components/formComponents/DockSelectionInput";
import DateInput from "@/components/formComponents/DateInput";
import TimeInput from "@/components/formComponents/TimeInput";
import ReservationError from "@/components/errorComponents/ReservationError";
import NumberInput from "@/components/formComponents/NumberInput";
import HttpRequests from "@/components/utilComponents/HttpRequests";
import FormUtils from "@/components/utilComponents/FormUtils";

export default {
  name: "reservationForm",
  components: {
    DockSelectionInput,
    DateInput,
    TimeInput,
    ReservationError,
    NumberInput,
  },
  mixins: [HttpRequests, FormUtils],
  data: function () {
    return {
      departureData: {
        label: "vertreksteiger",
        field: "departureLocation",
      },
      arrivalData: {
        label: "aankomststeiger",
        field: "arrivalLocation",
      },
      isHousehold: "",
      isNotHousehold: "",
      household: "",
      transHousehold: "",
      transHasHousehold: "",
      transHasNoHousehold: "",
      //Translatables
      transSubmit: "",
      transPrice: "",
      enableBtn: false,
      startAnalytics: false,
    };
  },
  computed: {
    submitStateClasses: function () {
      return {
        "btn-primary": this.enableBtn,
        disabled: !this.enableBtn,
        btn: true,
        "anchor-to-bottom": true,
      };
    },
    getHouseHoldValidation() {
      return (
        this.$store.state.booking["household"] == null &&
        this.$store.state.booking.disabledClick
      );
    },
  },
  created() {
    // New COVID rules are not based on households.
    this.updateHousehold("ishousehold");

    let booking = this.$store.getters.getBooking;
    this.household = booking["household"];

    //check for invalid location taxi trip
    let startLocationId =
      this.$store.getters.getlocation("departureLocation").id;
    let endLocationId = this.$store.getters.getlocation("arrivalLocation").id;
    let isDefaultLocation = startLocationId == 0 && endLocationId == 0;

    if (!isDefaultLocation && startLocationId == endLocationId) {
      this.$store.commit("changeLocation", { type: "resetArrivalLocation" });
    }
  },
  mounted() {
    this.updateLocale();
    this.$root.$on("change-locale", () => {
      this.updateLocale();
    });

    this.$root.$on("switch-tab", () => {
      let booking = this.$store.getters.getBooking;
      this.household = booking.household;
      this.isHousehold = this.$store.getters.getStaticHousehold("ishousehold");
      this.isNotHousehold =
        this.$store.getters.getStaticHousehold("nohousehold");
    });

    setTimeout(() => {
      this.startAnalytics = true;
    }, 2000);
  },
  methods: {
    updateHousehold(type) {
      this.$store.commit("setHousehold", {
        value: this.$store.getters.getStaticHousehold(type),
      });
      this.validateBooking();
    },
    updateLocale() {
      this.transSubmit = this.$gettext("checkAvailability");
      this.transPrice = this.$gettext("price");
      this.transHousehold = this.$gettext("houseHold");
      this.transHasNoHousehold = this.$gettext("hasNoHousehold");
      this.transHasHousehold = this.$gettext("hasHousehold");
    },
    openMaps(inputData) {
      this.$emit("open-maps", inputData);
    },
    validateBooking() {
      if (this.startAnalytics) {
        this.$store.commit("setAnalyticsPageStep", 1);
      }

      this.isTaxiRideSessionValid().then((valid) => {
        if (valid) {
          this.enableBtn = true;
          this.getPrice();
        } else {
          this.enableBtn = false;
          this.$store.commit("resetPrice");
        }
      });
    },
    toBooking() {
      if (this.enableBtn) {
        this.$store.commit("setClickDisabled", false);
        this.createBooking()
          .then((response) => {
            this.$store.commit("updateBookingSession", response);
            this.$router.push("/booking");
          })
          .catch((error) => {
            this.$store.commit("updateBookingSession", error.response);
            this.$router.push("/booking");
          });

        this.$ga.event({
          eventCategory: "Reservation",
          eventAction: "click",
          eventLabel: "Availability button",
        });
      } else {
        this.$store.commit("setClickDisabled", true);
        this.$ga.event({
          eventCategory: "Reservation",
          eventAction: "click",
          eventLabel: "Disabled availability taxi button",
        });
      }
    },
  },
};
</script>