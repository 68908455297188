<template>
  <div>
  		
	<div class="close-container hidden-sm hidden-md hidden-lg" id="mapCloseContainer">
		<div class="img back-icn"></div>
			{{ transBack }}
			
	</div>
	<div class="close-container hidden-sm hidden-md hidden-lg" id="mapTitleContainer">
		<h2></h2>
	</div>
	<div class="search-container" id="mapSearchContainer">
		<button v-on:click="closeMaps()" class="back"></button>
		<input @keyup.enter="searchPlaces()" v-model="search" ref="google-autocomplete" type="text" class="No-doc-click" id="mapSearch" :placeholder="transSearchPlaceholder">
		<button @click="searchPlaces()" class="search-places"></button>
		<button @click="getUserLocation()" class="location"></button>

		<div class="c-error_no-location-access" v-if="currentError !== ''"> 		
			<button @click="resetError()" class="close-button"></button>
 			<p>{{ transLocationError }}</p></div>
	</div>

	<div class="legend">
		<h3>Zones</h3>
		<img src="/images/legend.svg" width="84" height="23" />
	</div>

	<gmap-map
		ref="googlemaps"
		id="map"
		:center="center"
		:zoom=13
		:options="{
			disableDefaultUI: true,
			zoomControl: true,
			clickableIcons: true,
			gestureHandling: 'cooperative'
		}">

		<gmap-marker
			v-for="(location, index) in locations"
			:key="location.id"
			:position="{lat: location.latitude, lng: location.longitude}"
			:icon="location.icon"
			:zone="location.zone"
			:jettyNumber="location.jettyNumber"
			:selected="location.selected"
			:zIndex="location.zIndex"
			@mouseover="mouseOverMarker(index)"
			@mouseout="mouseOutMarker(index)"
			@click="openInfoWindow(index)"
			:label="{
				text: location.jettyNumber.toString(),
				color: '#fff',
                fontSize: '13px',
                fontFamily: 'din_round_otbold'
			}">

			<gmap-info-window
				:opened="location.infoBoxOpen">
					<div class="infoWindow" style="width: 300px;">
						<div id="dock-zone" :class="getZoneClass(location.zone)">{{ getZoneClassText(location.zone) }}</div>
						<h2 id="infoWindow-location">{{ location.name }}</h2>
						<div @click="closeInfoWindow()" id="close-btn"></div>
						<a @click="selectDock(location)" class="btn btn-primary google-maps-infowindow-button">{{ transInfoWindowButton }} <b>{{ setInfoBoxButtonLabel(selectionData.inputData) }}</b></a>
						<span></span>
					</div>
			</gmap-info-window>

		</gmap-marker>

	</gmap-map>

	<div class="clearfix"></div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
	data: function(){
		return {
			locations: [],
			center: {lat: 51.9004, lng: 4.46849},
			selectionData: {},
			// Info window
			infoWindowZoneClass: "",
			infoWindowZoneText: "",
			InfoWindowName: "",
			infoWindowOpen: false,
			infoWindowPosition: {},
			search: "",
			searchMarkers: [],
			searchInfoWindow: null,
			//Translatables
      		transSearchPlaceholder: "",
			transInfoWindowButton: "",
			currentError: "",
			transBack: "",
			transLocationError: "",
		};
	},
	mounted: function(){
		this.updateLocale();
		this.$root.$on("change-locale", () => {
			this.updateLocale();
		});
	},
	methods: {
		getMarkerIcon(icon){
			if(process.env.NODE_ENV == "development"){
				return require(`@/assets/images/${icon}.svg`);
			}else{
				let base = process.env.VUE_APP_PUBLIC_URL;
				return `${base}/images/${icon}.svg`;
			}			
		},
		updateLocale(){
			this.transSearchPlaceholder = this.$gettext("searchAdresOrLocation");
			this.transInfoWindowButton = this.$gettext("chooseAs");
			this.transBack = this.$gettext("GMapBack");
			this.transLocationError = this.$gettext("GMapLocationError");
		},
		setInfoBoxButtonLabel(data){
			return this.$gettext(data.field)
		},
		initMap(locations, selectionData) {
			let selectedLocation = {};
			if(selectionData.inputData.field == "departureLocation"){
				selectedLocation = this.$store.getters.getlocation("arrivalLocation");
			}else{
				selectedLocation = this.$store.getters.getlocation("departureLocation");
			}

			let updatedLocations = [];
			// Set maps specific data
			for(var i = 0; i < locations.length; i++) {
				if(selectedLocation != null && selectedLocation.jettyNumber != locations[i].jettyNumber){
					locations[i].selected = false;
					locations[i].infoBoxOpen = false;
					locations[i].zIndex = locations[i].jettyNumber;
					locations[i].icon = this.getIcon(locations[i].zone);
					updatedLocations.push(locations[i]);
				}
			}
			this.selectionData = selectionData;
			this.locations = updatedLocations;
			this.search = selectionData.search;
			this.searchPlaces();			
		},
		getUserLocation() {
			this.$getLocation({}).then(coordinates => {
				this.$refs.googlemaps.$mapPromise.then((map) => {
					map.panTo(coordinates);
				});
			}).catch(error=> {
				this.currentError = error;
			});
		},
		resetError(){
			this.currentError = "";
		},
		getIcon(zone) {
			switch(zone){
				case "centre":
					return this.getMarkerIcon("pinpoint_on_map_inner");
				break;
				case null:
					return this.getMarkerIcon("pinpoint_on_map_outside");
				break;
				default:
					return this.getMarkerIcon("pinpoint_on_map_outer");
				break;
			}
		},
		getZoneClass(zone) {
			switch(zone){
				case "centre":
					return "centrum";
				break;
				case null:
					return "buiten";
				break;
				default:
					return "";
				break;
			}
		},
		getZoneClassText(zone) {
			if(zone){
				return this.$gettext(zone);
			}else{
				return this.$gettext("outside");
			}
		},
		searchPlaces() {
			if(this.search != ""){

				let rotterdam = new google.maps.LatLng(51.9004, 4.46849);
				let searchMarkers = this.searchMarkers;
				let self = this;
				let request = {
	                location: rotterdam,
	                radius: 5000,
	                query: this.search
	            };

	            this.$refs.googlemaps.$mapPromise.then((map) => {
					let service = new google.maps.places.PlacesService(map);

					self.searchInfoWindow = new google.maps.InfoWindow();

					service.textSearch(request, function(results, status){
						
						if (status == google.maps.places.PlacesServiceStatus.OK) {
			                
			                for (var i = 0; i < searchMarkers.length; i++) {
				                searchMarkers[i].setMap(null);
				            }
			                
			                searchMarkers = [];

			                $.each(results, function (index, place) {
			                    var dist = google.maps.geometry.spherical.computeDistanceBetween(rotterdam, place.geometry.location);
			                    if (dist <= 12000) {

                                    var marker = new google.maps.Marker({
			                            position: place.geometry.location,
			                            map: map
			                        });
			                        marker.addListener('click', function () {
			                            self.searchInfoWindow.setContent(place.name);
			                            self.searchInfoWindow.open(map, marker);
			                        });
			                        searchMarkers.push(marker);
			                    }
			                });

			                if (searchMarkers.length == 1) {
			                    map.setCenter(results[0].geometry.location);
			                    map.setZoom(15);

			                } else {
			                    map.setCenter(rotterdam);
			                    map.setZoom(13);
			                }
			            }

			            // Give component the markers
			            self.searchMarkers = searchMarkers;
					});
				});
			}
		},
		// TODO: Not really setting the zindex, need to dig deeper
		mouseOverMarker(locationIndex) { 
			this.locations[locationIndex].zIndex = 99998;
		},
		mouseOutMarker(locationIndex) { 
			this.locations[locationIndex].zIndex = this.locations[locationIndex].jettyNumber; 
		},
		openInfoWindow(selectedLocationIndex){
			this.setSelectedLocation(selectedLocationIndex);
		},
		setSelectedLocation(selectedLocationIndex){
			this.unselectLocations();
			// Set selected
			this.locations[selectedLocationIndex].zIndex = 88888;
			this.locations[selectedLocationIndex].icon = this.getMarkerIcon("pinpoint_on_map_active");
			this.locations[selectedLocationIndex].selected = true;
			this.center = {
				lat: this.locations[selectedLocationIndex].latitude,
				lng: this.locations[selectedLocationIndex].longitude
			};

			// Alter infobox as soon as it opens
			this.openInfoBox(this.locations[selectedLocationIndex]).then((location) => {
				this.locations[selectedLocationIndex] = location;

				// TODO: Using jQuery... Use a vue or vanilla js solution
				var iwOuter = $('.gm-style-iw');
	            iwOuter.parent().parent().css({ top: '20px' });
	            iwOuter.css({ 
	            	top: '0px',
	            	left: '0px',
	            	padding: '0px'
	            });

	            // Hide default infobox button
	            iwOuter.parent().find('button.gm-ui-hover-effect').hide();
	            
	            var iwBackground = iwOuter.prev();
	            iwBackground.css({ 'display': 'none' });
	            var iwCloseBtn = iwOuter.next();

	            iwCloseBtn.hide();
	            iwOuter.children().eq(0).css({ overflow: 'visible' });
				
			});
		},
		unselectLocations() {
			// Unselect dock locations
			for(var i = 0; i < this.locations.length; i++) {
				this.locations[i].icon = this.getIcon(this.locations[i].zone);
				this.locations[i].zIndex = this.locations[i].jettyNumber;
				this.locations[i].selected = false;
				this.locations[i].infoBoxOpen = false;
			}

			// Unselect search
			if(this.searchInfoWindow != null){
				this.searchInfoWindow.close();
			}
		},
		openInfoBox(location) {
			return new Promise(function(resolve){
				location.infoBoxOpen = true;
				resolve(location);
			});
		},
		closeInfoWindow() {
			// Closed scope. 
			var updatedLcoations = [];
			for(var i = 0; i < this.locations.length; i++) {
				this.locations[i].icon = this.getIcon(this.locations[i].zone);
				this.locations[i].zIndex = this.locations[i].jettyNumber;
				this.locations[i].selected = false;
				this.locations[i].infoBoxOpen = false;
				updatedLcoations.push(this.locations[i]);
			}
			// Update parent component
			this.locations = updatedLcoations;
		},
		selectDock(dock) {
			this.closeInfoWindow();
			this.closeMaps();
			this.$root.$emit('select-dock', {
				inputData: this.selectionData.inputData,
				dock: dock
			})
		},
		closeMaps() {
			this.$emit('close-maps')
		}
	}
}

</script>