<template>
  <div>
    <!-- Box Form -->
    <div class="form-content" rel="rondvaart">
      <!-- Box Form - Introduction -->
      <div class="row">
        <div class="col-xs-12">
          <p class="info-intro-text">{{ transDescription }}</p>
        </div>
      </div>

      <!-- Box Form - Docks -->
      <div class="row">
        <div class="col-xs-12">
          <DockSelectionInput label="departureLocationC" inputName="departureLocation" :inputData="departureData"
            v-on:open-maps="openMaps" @onChange="updateLocation"></DockSelectionInput>

          <div class="form-group checkbox-group">
            <input ref="arrivalCheckbox" v-on:click="openArrivalInput" v-model="isArrivalOpen" type="checkbox"
              name="different-end-point" id="different-end-point" />
            <label for="different-end-point"></label>
            <span @click="openArrivalInputSpan">{{
            transDifferentEndpoint
            }}</span>
          </div>

          <DockSelectionInput label="arrivalLocationC" inputName="arrivalLocation" :inputData="arrivalData"
            v-on:open-maps="openMaps" @onChange="updateLocation" v-show="isArrivalOpen"></DockSelectionInput>
        </div>
      </div>

      <!-- Box Form - Date and Time -->
      <div class="row">
        <div class="col-xs-6 diff-gutter">
          <DateInput label="date" name="date" bookingType="Taxirit" placeholder="datePlaceholder"
            @onChange="updateDate"></DateInput>
        </div>
        <div class="col-xs-6 diff-gutter">
          <TimeInput label="time" nameHours="time-hour" nameMinutes="time-minutes" placeholderHours="hour"
            placeholderMinutes="min" @onChange="updateTime"></TimeInput>
        </div>
        <ReservationError :showError="!enableBtn" />
      </div>

      <!-- Box Form - People -->
      <div class="row">
        <div class="col-xs-6 diff-gutter">
          <NumberInput label="passengers" name="pax" placeholder="insertNumber" @onChange="updateNumber"></NumberInput>
        </div>
        <div class="col-xs-6 diff-gutter">
          <SelectInput label="durationC" inputName="duration" :options="durationOptions" placeholder="duration"
            storeName="setDuration" @onChange="updateDuration"></SelectInput>
        </div>
      </div>

      <!-- Box Form - Boottype -->
      <!-- <div class="row">
        <div class="col-xs-12 radio-group">
          <div class="form-group">
            <label for="boatType">{{ transBoottype }}</label>
            <div class="row radio-group">
              <div class="col-xs-6">
                <input
                  @click="updateBoatType('fast')"
                  type="radio"
                  id="fast"
                  :value="cruiseFast"
                  v-model="boatType"
                  name="boatType"
                  checked
                />
                <label class="rondvaart-radio" for="fast">
                  {{ transFast }}
                  <small>{{ transPassengersFast }}</small>
                </label>
              </div>
              <div class="col-xs-6">
                <input
                  @click="updateBoatType('classic')"
                  type="radio"
                  id="classic"
                  :value="cruiseClassic"
                  v-model="boatType"
                  name="boatType"
                />
                <label class="rondvaart-radio" for="classic">
                  {{ transClassic }}
                  <small>{{ transPassengersClassic }}</small>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- Household Check -->
      <!--<div class="row">
        <div class="col-xs-12 diff-gutter">
          <div class="form-group">
              <div class="form-box">
            <label>
              {{transHousehold}}
            </label>
            <div class="row radio-group center" :class="{'alert-text': getHouseHoldValidation}">
              <div class="col-xs-12 radio-box">
                <input @click="updateHousehold('ishousehold')" id="ishouseholdCruise" type="radio" :value="isHousehold" v-model="household" name="householdCheckCruise">
                <label class="label-small" for="ishouseholdCruise">
                  {{transHasHousehold}}     
                </label>
              </div>
              <div class="col-xs-12 radio-box">
                <div class="label-small">
                <input  @click="updateHousehold('nohousehold')" id="nohouseholdCruise" type="radio" :value="isNotHousehold" v-model="household" name="householdCheckCruise">
                <label class="label-small" for="nohouseholdCruise">
                    {{transHasNoHousehold}}
                </label>
              </div>              
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>-->

      <!-- Box Form - Price -->
      <p v-show="enableBtn" class="price">
        {{ this.transPrice }}
        <span class="bold din">{{
        this.formatPrice(this.$store.getters.getSessionedPrice)
        }}</span>
      </p>

      <!-- Box Form - Submit -->
      <input type="submit" :class="submitStateClasses" :value="transSubmit" @click="toBooking()" />
    </div>
  </div>
</template>

<script>
import NumberInput from "@/components/formComponents/NumberInput";
import SelectInput from "@/components/formComponents/SelectInput";
import DateInput from "@/components/formComponents/DateInput";
import TimeInput from "@/components/formComponents/TimeInput";
import ReservationError from "@/components/errorComponents/ReservationError";
import DockSelectionInput from "@/components/formComponents/DockSelectionInput";
import HttpRequests from "@/components/utilComponents/HttpRequests";
import FormUtils from "@/components/utilComponents/FormUtils";

export default {
  components: {
    DockSelectionInput,
    NumberInput,
    SelectInput,
    DateInput,
    TimeInput,
    ReservationError,
  },
  mixins: [HttpRequests, FormUtils],
  data: function () {
    return {
      cruiseFast: "",
      cruiseClassic: "",
      boatType: "fast",
      isHousehold: "",
      isNotHousehold: "",
      household: "",
      isArrivalOpen: false,
      enableBtn: false,
      departureData: {
        label: "vertreksteiger",
        field: "departureLocation",
      },
      arrivalData: {
        label: "aankomststeiger",
        field: "arrivalLocation",
      },
      durationOptions: [
        { value: 15, name: "15 minuten" },
        { value: 30, name: "30 minuten" },
        { value: 45, name: "45 minuten" },
        { value: 60, name: "60 minuten" },
        { value: 75, name: "75 minuten" },
        { value: 90, name: "90 minuten" },
        { value: 105, name: "105 minuten" },
        { value: 120, name: "120 minuten" },
      ],
      //Translatables
      transDescription: "",
      transDifferentEndpoint: "",
      transBoottype: "",
      transFast: "",
      transClassic: "",
      transPassengersFast: "",
      transPassengersClassic: "",
      transSubmit: "",
      transPrice: "",
      transHousehold: "",
      transHasHousehold: "",
      transHasNoHousehold: "",
    };
  },
  computed: {
    submitStateClasses: function () {
      return {
        "btn-primary": this.enableBtn,
        disabled: !this.enableBtn,
        btn: true,
        "anchor-to-bottom": true,
      };
    },
    getHouseHoldValidation() {
      return (
        this.$store.state.booking["household"] == null &&
        this.$store.state.booking.disabledClick
      );
    },
  },
  mounted() {
    this.updateLocale();
    this.$root.$on("change-locale", () => {
      this.updateLocale();
    });

    this.$root.$on("switch-tab", () => {
      let booking = this.$store.getters.getBooking;
      this.household = booking["household"];
    });
  },
  created() {
    // New COVID rules are not based on households.
    this.updateHousehold("ishousehold");

    let booking = this.$store.getters.getBooking;
    this.boatType = booking.boatType;
    this.cruiseFast = this.$store.getters.getStaticBoatType("fast");
    this.cruiseClassic = this.$store.getters.getStaticBoatType("classic");

    this.household = booking.household;
    this.isHousehold = this.$store.getters.getStaticHousehold("ishousehold");
    this.isNotHousehold = this.$store.getters.getStaticHousehold("nohousehold");

    this.validateBooking();
  },
  methods: {
    updateLocale() {
      let count = 15;
      for (let i = 0; i < this.durationOptions.length; i++) {
        this.durationOptions[i].name =
          count.toString() + " " + this.$gettext("minute");
        count = count + 15;
      }
      count = 15;

      this.transSubmit = this.$gettext("checkAvailability");
      this.transDescription = this.$gettext("roundTripDescription");
      this.transDifferentEndpoint = this.$gettext("differentEndPoint");
      this.transBoottype = this.$gettext("boatType");
      this.transFast = this.$gettext("fast");
      this.transClassic = this.$gettext("classic");
      this.transPrice = this.$gettext("price");

      this.transHousehold = this.$gettext("houseHold");
      this.transHasNoHousehold = this.$gettext("hasNoHousehold");
      this.transHasHousehold = this.$gettext("hasHousehold");

      let maxPeople = this.$gettext("maxPeople");
      this.transPassengersFast = this.$gettextInterpolate(maxPeople, {
        amount: 12,
      });
      this.transPassengersClassic = this.$gettextInterpolate(maxPeople, {
        amount: 8,
      });
    },
    openMaps(inputData) {
      this.$emit("open-maps", inputData);
    },
    updateHousehold(type) {
      this.$store.commit("setHousehold", {
        value: this.$store.getters.getStaticHousehold(type),
      });
      this.validateBooking();
    },
    updateBoatType(type) {
      this.$store.commit("setBoatType", {
        value: this.$store.getters.getStaticBoatType(type),
      });
      this.validateBooking();
    },
    validateBooking() {
      this.isCruiseSessionValid().then((valid) => {
        if (valid) {
          this.enableBtn = true;
          this.getPrice();
        } else {
          this.enableBtn = false;
          this.$store.commit("resetPrice");
        }
      });
    },
    openArrivalInputSpan() {
      if (this.isArrivalOpen) {
        this.isArrivalOpen = false;
      } else {
        this.isArrivalOpen = true;
      }
      this.openArrivalInput();
    },
    openArrivalInput() {
      this.$store.commit("setArriveAtDifferentLocation", {
        value: this.isArrivalOpen,
      });
      this.validateBooking();
    },
    toBooking() {
      if (this.enableBtn) {
        this.$store.commit("setClickDisabled", false);
        this.$ga.event({
          eventCategory: "Reservation",
          eventAction: "click",
          eventLabel: "Availability button",
        });

        let booking = this.$store.getters.getBooking;
        if (booking.arriveAtDifferentLocation == false) {
          this.$store.commit("changeLocation", {
            type: "arrivalLocation",
            location: booking.departureLocation,
          });
        }

        this.createBooking()
          .then((response) => {
            this.$store.commit("updateBookingSession", response);
            this.$router.push("/booking");
          })
          .catch((error) => {
            this.$store.commit("updateBookingSession", error.response);
            this.$router.push("/booking");
          });
      } else {
        this.$store.commit("setClickDisabled", true);
        this.$ga.event({
          eventCategory: "Reservation",
          eventAction: "click",
          eventLabel: "Disabled availability tour button",
        });
      }
    },
  },
};
</script>