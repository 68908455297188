<template>
  <div>
    
    <div class="row row-eq-height">
      <!-- Change translation! DEV ONLY 
      <div class="test-buttons">
        <button @click="changeLanguage('en_US')">English</button>
        <button @click="changeLanguage('nl_NL')">Dutch</button>
      </div>
      -->

      <!-- Left view - Form -->
      <div class="col-xs-12 col-sm-6 col-lg-5">        
        <div class="box">
          <!-- Box Navigation -->
          <div class="row">
            <!-- Navigation -->
            <div class="col-xs-12">
              <ul class="tabs">
                <li
                  v-on:click="showReservationForm()"
                  v-bind:class="{ active: reservationActive }">
                  <translate>bookTaxi</translate>
                </li>
                <li
                  v-on:click="showTourForm()"
                  v-bind:class="{ active: tourActive }">
                  <translate>bookRoundTrip</translate>
                </li>
              </ul>
            </div>
            <!-- Errors -->
            <div class="col-xs-12">
              <p class="alert error hidden"></p>
            </div>
          </div>

          <!-- Form here -->
          <ReservationForm 
            ref="reservationForm"
            v-show="reservationActive" 
            v-on:open-maps="openMaps"></ReservationForm>

          <TourForm 
            ref="tourForm"
            v-show="tourActive" 
            v-on:open-maps="openMaps"></TourForm>
        </div>
      </div>

      <!-- Right view - Google maps -->
      <div class="col-xs-12 col-sm-6 col-lg-7 map-container">
        <GoogleMaps ref="googlemaps" v-show="showMap" v-on:close-maps="closeMaps"></GoogleMaps>
      </div>
    </div>
    
  </div>
</template>

<script>
import ReservationForm from "@/components/ReservationForm";
import TourForm from "@/components/TourForm";
import GoogleMaps from "@/components/GoogleMaps";
import HttpRequests from "@/components/utilComponents/HttpRequests";
import Vue from 'vue'
import $ from "jquery";
export default {
  name: "home",
  mixins: [HttpRequests],
  components: {
    ReservationForm,
    TourForm,
    GoogleMaps
  },
  created() {
    // Set event when user closes/navigates tab/window
    window.addEventListener('beforeunload', this.setCloseEvent)

    this.getLocations();
    // Set initial locale
    // The locale can be found in an element outside of vue that has the [data-reservation-locale] data attributes:
    // For example: <div data-reservation-locale="en"></div>
    // TODO: using jquery, find a vue solution
    switch($("[data-reservation-locale]").data("reservation-locale")){
      case "nl": Vue.config.language = "nl_NL"; break;
      case "en": Vue.config.language = "en_US"; break;
      default: Vue.config.language = "nl_NL"; break;
    }
    this.$root.$emit("change-locale");
  },
  mounted(){
    let booking = this.$store.getters.getBooking;
    switch(booking.bookingType){
      case "TAXI_RIDE": 
        this.showReservationForm();
        this.$refs.reservationForm.validateBooking();
      break;
      case "CRUISE": 
        this.showTourForm();
        this.$refs.tourForm.validateBooking();
      break;
    }
  },
  data() {
    return {
      reservationActive: true,
      tourActive: false,
      showMap: false
    };
  },
  methods: {
    setCloseEvent(event){
      this.$ga.event({
        eventCategory: 'Progress',
        eventAction: 'close',
        eventLabel: this.$store.getters.getWindowClosedAnalyticsMessage
      });
    },
    changeLanguage(lan){
      Vue.config.language = lan;
      this.$root.$emit("change-locale");
    },
    showReservationForm() {
      this.reservationActive = true;
      this.tourActive = false;
      this.$store.commit("setBookingType", {
        bookingType: "TAXI_RIDE"
      });
      this.$root.$emit("switch-tab");

      this.$ga.event({
        eventCategory: 'Page',
        eventAction: 'click',
        eventLabel: 'Visit Reservation Form'
      });

      this.$refs.reservationForm.validateBooking();
    },
    showTourForm() {
      this.reservationActive = false;
      this.tourActive = true;
      this.$store.commit("setBookingType", {
        bookingType: "CRUISE"
      });
      this.$root.$emit("switch-tab");

      this.$ga.event({
        eventCategory: 'Page',
        eventAction: 'click',
        eventLabel: 'Visit Tour Form'
      });

      this.$refs.tourForm.validateBooking()
    },
    openMaps(inputData) {
      this.$refs.googlemaps.initMap(
        this.$store.getters.getApiLocations,
        inputData
      );
      this.showMap = true;
    },
    closeMaps() {
      this.showMap = false;
    }
  }
};
</script>