<script>
import moment from "moment";
export default {
  data() {
    return {
      today: moment().format("DD/MM/YYYY"),
      isToday: false,
      enableBtn: false
    };
  },
  methods: {
    formatPrice(value) {
      if (value !== 0 && value !== null) {
        let val = (value / 1).toFixed(2).replace(".", ",");
        val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        let valArr = val.split(","); 

        if(valArr[1] == "00"){
          return "€" + valArr[0];
        }else{
          return "€" + val;
        }
        
      } else {
        return "";
      }
    },
    updateDate() {
      this.checkTodayDate();
      this.validateBooking();
    },
    updateTime() {
      this.validateBooking();
    },
    updateNumber() {
      this.validateBooking();
    },
    updateLocation() {
      this.validateBooking();
    },
    updateDuration() {
      this.validateBooking();
    },
    updateHousehold() {
      this.validateBooking();
    },
    checkTodayDate() {
      this.isToday = this.$store.getters.getdate === this.today;
      this.$store.commit("setBookingToday", this.isToday);
    },
    async isTaxiRideSessionValid() {
      let booking = this.$store.getters.getBooking;
      if (booking.bookingType !== "TAXI_RIDE") {
        return false;
      } else {
        let returnValues = [];
        this.$store.commit("setErrorActive", false);
        let returnValue = true;
        let bookingEntries = Object.entries(booking);
        let key = "";
        let value = "";
        for(let i = 0; i < bookingEntries.length; i++){
          key = bookingEntries[i][0]
          value = bookingEntries[i][1]
          returnValue = this.validateDayClosed(returnValue, key, value)
          returnValue = await this.validateHours(returnValue, key, value)
          returnValue = this.validateLocation(returnValue, key, value);
          returnValue = this.validateSameLocaton(returnValue, booking, key, value);
          returnValue = this.validateChildren(returnValue, booking, key, value);
          returnValue = this.validateValue(key, returnValue, value);
          returnValue = this.validateMinutes(returnValue, key, value);
          returnValue = this.validateHousehold(returnValue, key, value)
          returnValue = this.allowedEmpty(returnValue, key, value, ["disabledClick", "closedForTheDay", "todayBooking", "price", "pax", "boatType", "duration", "arriveAtDifferentLocation", "children", "minutes"]);
          returnValues.push(returnValue);
        }        
        return !returnValues.includes(false);
      }
    },

    async isCruiseSessionValid() {
      let booking = this.$store.getters.getBooking;
      if (booking.bookingType !== "CRUISE") {
        return false;
      } else {
        let returnValues = [];
        this.$store.commit("setErrorActive", false);
        let returnValue = true;
        let bookingEntries = Object.entries(booking);
        let key = "";
        let value = "";
        for(let i = 0; i < bookingEntries.length; i++){
          key = bookingEntries[i][0]
          value = bookingEntries[i][1]
          returnValue = this.validateDayClosed(returnValue, key, value)
          returnValue = await this.validateHours(returnValue, key, value)
          returnValue = this.validateDuration(returnValue, key, value);
          returnValue = this.validateDepartureLocation(returnValue, key, value)
          returnValue = this.validateBoatType(returnValue, key, value)
          if(booking.arriveAtDifferentLocation){
            returnValue = this.validateSameLocaton(returnValue, booking, key, value);
          }
          returnValue = this.validateValue(key, returnValue, value);
          returnValue = this.validateMinutes(returnValue, key, value);
          returnValue = this.validateHousehold(returnValue, key, value)
          if(booking.arriveAtDifferentLocation){
            returnValue = this.allowedEmpty(returnValue, key, value, ["disabledClick", "closedForTheDay","todayBooking", "price", "adults", "children", "arriveAtDifferentLocation", "minutes"]);
          }else{
            returnValue = this.allowedEmpty(returnValue, key, value, ["disabledClick", "closedForTheDay","todayBooking", "price", "adults", "children", "arrivalLocation", "arriveAtDifferentLocation", "minutes"]);
          }
          returnValues.push(returnValue);
        }
        return !returnValues.includes(false);
      }
    },

    // Validations
    validateDayClosed(returnValue, key, value){
      if(key == "dateTime" && value != null || value != ""){
        if(this.$store.state.booking.closedForTheDay){
          this.$store.commit("setErrorActive", true);
          this.$store.commit("setErrorKey", "ClosedForTheDay");
          returnValue = false;
        }
      }      
      return returnValue
    },

    async validateHours(returnValue, key, value){

      if(key == "hours" && this.isToday){
        if (value != null && value != "") {

          // Check if hours is in the past
          let todayDate = moment();
          let reservationDate = this.$store.getters.getMomentDate;
          if(reservationDate.isBefore(todayDate)){
            this.$store.commit("setErrorActive", true);
            this.$store.commit("setErrorKey", "InvalidDateError");
            return false;
          }

          // Check if hours is within a certain range
          return this.sameDayValidation(
            this.$store.getters.getbookingtype,
            this.$store.getters.getformatteddate
          ).then(_response => {
            return true;
          }).catch(error => {
            let result = error.response.data
            if(result != true && result.error == "PreCheckTimeTooShortTermError"){
              this.$store.commit("setErrorActive", true);
              this.$store.commit("setErrorKey", "PreCheckTimeTooShortTermError");
              this.$store.commit("setErrorData", {bookingHorizon: result.extra.bookingHorizon});
              return false;
            }
          });
        }

      }else{
        return returnValue;
      }      
    },

    validateValue(key, returnValue, value){
      if (value == 0 || value === null || value === "") {
        returnValue = false;
      }
      if(key == "hours"){
        if(value == "00"){
          returnValue = true;
        }
      }
      return returnValue;
    },

    validateMinutes(returnValue, key, value){
      if(key == "minutes"){
        if (value === null || value === "") {
          returnValue = false;
        }else{
          returnValue = true;
        }
      }
      return returnValue;
    },

    validateHousehold(returnValue, key, value){
      if (key === "household") {
        var household = this.$store.getters.getStaticHousehold("ishousehold");
        var nohousehold = this.$store.getters.getStaticHousehold("nohousehold");
        if (value !== household && value !== nohousehold) {
          returnValue = false;
        }
      }
      return returnValue;
    },

    validateBoatType(returnValue, key, value){
      if (key === "boatType") {
        var fast = this.$store.getters.getStaticBoatType("fast");
        var classic = this.$store.getters.getStaticBoatType("classic");
        if (value !== fast && value !== classic) {
          returnValue = false;
        }
      }
      return returnValue;
    },

    validateLocation(returnValue, key, value){
      if (key === "departureLocation" || key === "arrivalLocation") {
        if (value.id === 0 || value.id === null || value.id === "") {
          returnValue = false;
        }
      }
      return returnValue;
    },

    validateDepartureLocation(returnValue, key, value){
      if (key === "departureLocation") {
        if (value.id === 0 || value.id === null || value.id === "") {
          returnValue = false;
        }
      }
      return returnValue;
    },

    validateDuration(returnValue, key, value){
      if (key === "duration") {
        let durationOptions = [15, 30, 45, 60, 75, 90, 105, 120];
        if (!durationOptions.includes(value)) {
          returnValue = false;
        }
      }
      return returnValue;
    },

    validateSameLocaton(returnValue, booking, key, value){
      if (key === "arrivalLocation") {
        if (booking.departureLocation.id == booking.arrivalLocation.id) {
          returnValue = false;
        }
      }
      return returnValue;
    },

    validateChildren(returnValue, booking, key, value) {
      if (key === "children") {
        if (
          booking.adults === 0 ||
          booking.adults === null ||
          booking.adults === "" ||
          booking.adults === "0"
        ) {
          returnValue = false;
        } else {
          returnValue = true;
        }
      }
      return returnValue;
    },

    allowedEmpty(returnValue, key, value, allowedList){
      if (allowedList.includes(key)) {
        // only one that is allowed to be empty
        returnValue = true;
      }
      return returnValue;
    }
  }
};
</script>
