<template>
  	<div class="col-xs-12 content-box alert warning reservation-error" >

  		<div v-if="hasAlternativeTimeSlots()">
	        <p v-html="getMessage('alternativeTimeSlots')"></p>

			<ul class="timeslots">
				<li 
			  v-for='timeslot in getErrorTimeslots()'
			  @click="updateTime(timeslot, $event)">{{ getTimeslot(timeslot) }}</li>
			</ul>

	        <input
			  type="submit"
			  class="btn btn-primary"
			  :value="transSubmit"
			  v-bind:disabled="!enableBtn"
			  @click="updateBooking()">
  		</div>
  	
		<div v-else-if="NoTimeSlotAvailable()">
			<p v-html="getMessage('NoTimeSlotAvailable')"></p>
		</div>

		<div v-else-if="isMaxReservationsError()">
  			<p v-html="getMessage('MAX_BOOKING_REQUESTS_ALLOWED')"></p>
  		</div>

  		<div v-else>
  			<p>{{ getMessage("BOOKING_CONFIRMATION_DEFAULT_INTRO") }}</p>
	        <ul>
	        	<li v-for="(error, index) in errors" :key="index">
	        		{{ getMessage(error.reason) }}
	        	</li>
	        </ul>
	        <p v-html="getMessage('BOOKING_CONFIRMATION_DEFAULT_CALL')"></p>
  		</div>
    </div>
</template>

<script>
import moment from "moment";
import $ from 'jquery'
export default {
	name: "auto-cconfirm-error",
	props: {
		errors: {
		  type: Array,
		  default: () => []
		}
	},
	data() {
		return {
			enableBtn: false,
			transSubmit: ""
		};
	},
	mounted() {
		this.updateLocale();
	    this.$root.$on("change-locale", () => {
	      this.updateLocale();
	    });
	},
	methods: {
		updateLocale(){
			this.transSubmit = this.$gettext("checkAvailability");
	    },
	    getMessage(reason){
			let gaMessages = {
				"MAX_BOOKING_REQUESTS_ALLOWED": "Max booking reached for the day",
				"TIME_IS_AFTER_MIDNIGHT": "Reservation is after midnight",
				"BOAT_TYPE_IS_CLASSIC": "Classic Watertaxi is selected",
				"DATE_IS_PAST_3_MONTHS_FROM_NOW": "Selected date is 3 months in the future",
				"REQUESTED_DURATION_IS_LONGER_THAN_60_MINUTES": "Selected duration of the boat trip is more than 60 minutes",
				"EXPECTED_DURATION_IS_LONGER_THAN_60_MINUTES": "Expected duration of the boat trip is longer than 60 minutes",
				"EXPECTED_DURATION_COULD_NOT_BE_CALCULATED": "Duration could not be calculated",
				"AUTO_CONFIRM_NOT_ACTIVE_IN_CONFIG": "Cannot reserve automatically",
				"LOCATION_NOT_IN_WATERTAXI_AREA": "Location is not within the Watertaxi area",
				"PAX_IS_MORE_THAN_12": "Selected more than 12 passengers",
				"PAX_IS_MORE_THAN_8": "Classic - Selected more than 8 passengers",
				"PRICE_COULD_NOT_BE_CALCULATED": "Price could not be calculated"
			};
			if(gaMessages[reason] != undefined){
				this.$ga.event({
					eventCategory: 'BookingConfirmation',
					eventAction: 'bookingRequest',
					eventLabel: gaMessages[reason]
				});
			}
	    	return this.$gettext(reason)
	    },
	    isMaxReservationsError(){
	    	return !this.$store.state.bookingSession.confirmationPossible;
	    },
	    NoTimeSlotAvailable(){
	    	return (!this.$store.state.bookingSession.confirmationPossible && this.$store.state.error.errorKey === "NoTimeSlotAvailable");
		},
		hasAlternativeTimeSlots(){
	    	return (!this.$store.state.bookingSession.confirmationPossible && this.$store.state.error.data.alternativeTimeSlots.length > 0);
	    },
	    getErrorTimeslots(){
	    	return this.$store.state.error.data.alternativeTimeSlots;
	    },
	    getTimeslot(timeslot){
	      return this.parseDateTime(moment(timeslot), 'HH:mm');
	    },
	    parseDateTime(momentObj, format) {
	      if(momentObj.isValid()){
	        return momentObj.format(format);
	      }else{
	        return "";
	      }
	    },
	    updateTime(timeslot){
	      $(event.target).parent().find("li").removeClass("selected");
	      $(event.target).toggleClass("selected");
	      this.$store.commit("setBookingHours", this.parseDateTime(moment(timeslot), 'HH'));
	      this.$store.commit("setBookingMinutes", this.parseDateTime(moment(timeslot), 'mm'));
	      this.enableBtn = true;
	    },
	    updateBooking(){
	    	this.$emit("update-booking");
	    }
	}
};
</script>

<style lang="scss">
#planner-container{

	.alert.warning.reservation-error{
		margin-left: 15px;
		margin-right: 15px;
		width: calc(100% - 30px);
	}

	.content-box{

		div{
			padding: 5px;
			p{
				font-size: 1.4rem;
				padding: 0px;
				margin: 0px;
			}

			ul{
				list-style-type: disc;
				margin: 15px 0px 15px 30px;

				li{
					font-size: 1.4rem;
				}
			}
		}
	}
}
</style>
