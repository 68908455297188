import Vue from 'vue'
import Vuex from 'vuex'
import moment from "moment";
import "moment-timezone";

Vue.use(Vuex)

var location = {
    id: 0,
    name: "",
    jettyNumber: 0,
    zone: "",
    latitude: 0,
    longitude: 0
};

var price = {
    totalPrice: 0,
    pppAdult: 0,
    pppChild: 0
};

// Order in steps
var analyticsWindowCloseMessage = [
    "Left without using the planner",
    "Left during the availability form",
    "Left during a failed availability check",
    "Left without starting the contact form",
    "Left during the contact form",
    "Left when the session expired",
    "Left after a successful booking"
];

export default new Vuex.Store({
    state: {
        static: {
            cruiseClassic: 1,
            cruiseFast: 2,
            isHousehold: 1,
            isNotHousehold: 2
        },
        api: {
            locations: [],
            closingHours: [0, 1, 2, 3, 4, 5, 6, 7, 8],
            fetchingHours: false
        },
        booking: {
            closedForTheDay: false,
            todayBooking: false,
            disabledClick: false,
            bookingType: "TAXI_RIDE",
            departureLocation: location,
            arrivalLocation: location,
            dateTime: null,
            hours: "",
            minutes: "",
            adults: "",
            children: "",
            household: null,
            price: null,
            boatType: 2,
            pax: "",
            duration: "",
            arriveAtDifferentLocation: false
        },
        bookingSession: {
            autoConfirm: true,
            autoConfirmRejectionReasons: [],
            confirmationPossible: true,
            valid: true,
            email: "",
            firstName: "",
            lastName: "",
            phone: "",
            price: price,
            timeSlotReservedInSeconds: 0,
            timeSlotReservedUntil: "",
            sessionKey: "",
            dateTime: null,
            id: 0,
            agreedToTermsConditions: false
        },
        sessionError: {
            valid: false,
            field: null,
            message: ""
        },
         error: {
            active: false,
            data: {},
            errorKey: "",
            message: "",
            type: "SOME_ERROR"
        },
        analyticsPageStep: 0
    },
    mutations: {
        setAnalyticsPageStep(state, step) {
            state.analyticsPageStep = step;
        },
        updateBookingSession(state, data) {
            let body = data.data;
            state.bookingSession.confirmationPossible = true;
            if(data.status >= 200 && data.status < 300){
                state.bookingSession.valid = true;
                state.bookingSession.bookingType = body.type;
                state.bookingSession.price = body.price;
                state.bookingSession.timeSlotReservedInSeconds = body.timeSlotReservedInSeconds;
                state.bookingSession.timeSlotReservedUntil = body.timeSlotReservedUntil;
                state.bookingSession.sessionKey = body.sessionKey;
                state.bookingSession.dateTime = body.date;
                state.bookingSession.id = body.id;
                state.bookingSession.autoConfirm = body.autoConfirm;
                if(!body.autoConfirm){
                    let reservationFull = body.autoConfirmRejectionReasons.find(function(error){
                        return error.reason === "MAX_BOOKING_REQUESTS_ALLOWED"
                    });
                    state.bookingSession.confirmationPossible = (reservationFull == undefined);
                    state.bookingSession.autoConfirmRejectionReasons = body.autoConfirmRejectionReasons;
                }
            }else{
                state.bookingSession.confirmationPossible = !(body.error === "NoTimeSlotAvailable");
                state.bookingSession.autoConfirm = false;
                state.bookingSession.valid = true;
                state.error.data = body;
                state.error.errorKey = body.error;
                state.error.message = body.message;
                state.error.type = body.type;
            }
        },
        updateState(state, data) {
            switch (data.key) {
                case "api":
                    switch (data.type) {
                        case "locations":
                            state.api.locations = data.body;
                            break;
                        case "closingHours":
                            state.api.closingHours = data.body;
                            break;
                    }
                    break
                case "booking":
                    switch (data.type) {
                        case "price":
                            state.booking.price = data.body.totalPrice;
                            break;
                    }
                    break
            }
        },
        changeLocation(state, data) {
            switch (data.type) {
                case "departureLocation":
                    state.booking.departureLocation = data.location;
                    break;
                case "arrivalLocation":
                    state.booking.arrivalLocation = data.location;
                    break;
                case "resetDepartureLocation":
                    state.booking.departureLocation = location
                    break;
                case "resetArrivalLocation":
                    state.booking.arrivalLocation = location
                    break;
            }
        },
        setArriveAtDifferentLocation(state, data){
            state.booking.arriveAtDifferentLocation = data.value;
        },
        setDate(state, data) {
            state.booking.dateTime = data.dateTime;
            state.booking.hours = "";
            state.booking.minutes = "";
        },
        setFetchingHours(state, isFetching){
            state.api.fetchingHours = isFetching;
        },
        setDuration(state, data) {
            state.booking.duration = data.value;
        },
        setBookingType(state, data) {
            state.booking.bookingType = data.bookingType;
        },
        setBoatType(state, data) {
            state.booking.boatType = data.value;
        },
        setBookingHours(state, hours){
            state.booking.hours = hours;
        },
        setBookingToday(state, today){
            state.booking.todayBooking = today;
        },
        setClosedForTheDay(state, closed){
            state.booking.closedForTheDay = closed;
        },
        setBookingMinutes(state, minutes){
            state.booking.minutes = minutes;
        },
        setHousehold(state, data) {
            state.booking.household = data.value;
        },
        resetHousehold(state) {
            state.booking.household = null;
        },
        setPAX(state, data) {
            switch (data.type) {
                case "adults":
                    state.booking.adults = data.number;
                    break;
                case "children":
                    state.booking.children = data.number;
                    break;
                case "pax":
                    state.booking.pax = data.number;
                    break;
            }
        },
        resetPrice(state) {
            state.booking.price = 0;
        },

        // Errors
        setErrorKey(state, key){
            state.error.errorKey = key;  
        },
        setErrorData(state, data){
            state.error.data = data;
        },
        setErrorActive(state, error){
            state.error.active = error;
        },
        setClickDisabled(state, status){
            state.booking.disabledClick = status;
        },

        // Session Setters
        setTerms(state, data) {
            state.bookingSession.agreedToTermsConditions = data.terms;
            state.analyticsPageStep = 4;
        },
        setFirstname(state, data) {
            state.bookingSession.firstName = data.value;
            state.analyticsPageStep = 4;
        },
        setLastname(state, data) {
            state.bookingSession.lastName = data.value;
            state.analyticsPageStep = 4;
        },
        setEmail(state, data) {
            state.bookingSession.email = data.value;
            state.analyticsPageStep = 4;
        },
        setPhone(state, data) {
            state.bookingSession.phone = data.value;
            state.analyticsPageStep = 4;
        },
        resetEverything(state, data){
            // Booking
            state.booking.bookingType = "TAXI_RIDE";
            state.booking.departureLocation = location;
            state.booking.arrivalLocation = location;
            state.booking.dateTime = null;
            state.booking.hours = "";
            state.booking.minutes = "";
            state.booking.adults = "";
            state.booking.children = "";
            state.booking.price = null;
            state.booking.boatType = null;
            state.booking.pax = null;
            state.booking.household = null;
            state.booking.duration = null;

            // Booking Session
            state.bookingSession.valid = true;
            state.bookingSession.email = "";
            state.bookingSession.firstName = "";
            state.bookingSession.lastName = "";
            state.bookingSession.phone = "";
            state.bookingSession.price = price;
            state.bookingSession.timeSlotReservedInSeconds = 0;
            state.bookingSession.timeSlotReservedUntil = "";
            state.bookingSession.sessionKey = "";
            state.bookingSession.dateTime = null;
            state.bookingSession.id = 0;
            state.bookingSession.agreedToTermsConditions = false;
        }
    },
    getters: {
        getWindowClosedAnalyticsMessage: (state) => {
            return analyticsWindowCloseMessage[state.analyticsPageStep];
        },
        getError: (state) => {
            return state.error;
        },
        getBookingSession: (state) => {
            return state.bookingSession;
        },
        getApiLocations: (state) => {
            return state.api.locations;
        },
        getlocation: (state) => (type) => {
            switch (type) {
                case "departureLocation":
                    return state.booking.departureLocation;
                    break;
                case "arrivalLocation":
                    return state.booking.arrivalLocation;
                    break;
            }
        },
        getdate: (state) => {
            return state.booking.dateTime
        },
        getBoatType: (state) => {
            return state.booking.boatType;
        },
        getDuration: (state) => {
            return state.booking.duration;
        },
        getbookingtype: (state) => {
            return state.booking.bookingType;
        },
        getformatteddate: (state) => {
            let date = moment("" + state.booking.dateTime + " " + state.booking.hours + ":" + state.booking.minutes + "", "DD/MM/YYYY HH:mm");
            return date.tz("Etc/UTC").format();
        },
        getMomentDate(state){
            return moment("" + state.booking.dateTime + " " + state.booking.hours + ":" + state.booking.minutes + "", "DD/MM/YYYY HH:mm");
        },
        gettime: (state) => (type) => {
            switch (type) {
                case "hours":
                    return state.booking.hours;
                    break;
                case "minutes":
                    return state.booking.minutes;
                    break;
            }
        },
        getPAX: (state) => (type) => {
            switch (type) {
                case "adults":
                    return state.booking.adults;
                    break;
                case "children":
                    return state.booking.children;
                    break;
                case "passengers":
                    return state.booking.pax;
                    break;
            }
        },
        getSessionedPrice: (state) => {
            return state.booking.price
        },
        getBooking: (state) => {
            return state.booking
        },
        getBookingSession: (state) => {
            return state.bookingSession
        },
        getStaticBoatType: (state) => (type) => {
            switch (type) {
                case "fast":
                    return state.static.cruiseFast;
                    break;
                case "classic":
                    return state.static.cruiseClassic;
                    break;
            }
        },
        getStaticHousehold: (state) => (type) => {
            switch (type) {
                case "ishousehold":
                    return state.static.isHousehold;
                    break;
                case "nohousehold":
                    return state.static.isNotHousehold;
                    break;
            }
        },
        getHousehold: (state) => {
            switch (state.booking.household) {
                case 1:
                    return true
                    break;
                case 2:
                    return false
                    break;
                case null:
                    return false
                    break;
            }
        }
    }
})
