<template>
  <div>
    <div class="box">
      <div class="row">
        <!-- Title and back -->
        <div class="col-xs-12 title-content-box">
          <div @click="backToHome()" class="img back-icn"></div>
          <h4>{{ transTitle }}</h4>
        </div>

        <div class="col-xs-12 content-box">
          <!-- Booking Type -->
          <h3 class="type">{{ transBookingType }}</h3>
          <ul class="route">
            <li>
              <b>{{ transDepartureDock }}</b
              ><br />
              <span class="point">{{ booking.departureLocation.name }}</span>
            </li>
            <li>
              <b>{{ transArrivalDock }}</b
              ><br />
              <span class="point">{{ booking.arrivalLocation.name }}</span>
            </li>
          </ul>

          <hr />

          <!-- Passengers -->
          <div class="row">
            <div class="col-xs-4">
              <h3 class="uppercase">{{ transPassengersC }}</h3>
              <p v-show="!cruise" class="pax">
                <span class="adults-icon"></span>
                <span class="person-indicator">{{ booking.adults }}</span>
                <span class="children-icon"></span>
                <span class="person-indicator">{{ booking.children }}</span>
              </p>
              <p v-show="cruise" class="pax">
                <span class="adults-icon"></span>
                <span class="person-indicator">{{ booking.pax }}</span>
              </p>
            </div>

            <div class="col-xs-4" v-show="cruise">
              <h3 class="uppercase">{{ transDuration }}</h3>
              <p class="pax extra-padding">{{ transDurationText }}</p>
            </div>

            <div class="col-xs-4" v-show="cruise">
              <h3 class="uppercase">{{ transBoatType }}</h3>
              <p class="pax extra-padding">{{ transBoatTypeText }}</p>
            </div>
          </div>

          <hr />

          <!-- Date and time -->
          <h3>{{ transDateAndTime }}</h3>
          <p class="datetime">
            <span>{{ getDate() }}</span> {{ transAt }}
            <span>{{ getTime() }}</span>
          </p>
        </div>

        <!-- Total Price -->
        <div class="col-xs-12 content-box">
          <hr />

          <p class="price">
            {{ transtotalPrice }}
            <!-- <span class="bold din"> &euro;{{ booking.price }} </span> -->

            <span class="bold din">{{ this.formatPrice(booking.price) }}</span>
          </p>
        </div>

        <!-- Manual reservation -->
        <auto-confirm-error
          v-show="session.valid && !session.autoConfirm"
          :errors="session.autoConfirmRejectionReasons"
          v-on:update-booking="updateBooking"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HttpRequests from "@/components/utilComponents/HttpRequests";
import FormUtils from "@/components/utilComponents/FormUtils";
import AutoConfirmError from "@/components/errorComponents/AutoConfirmError";
import moment from "moment";
import "moment/locale/nl";
import Vue from "vue";
export default {
  name: "bookingForm",
  mixins: [HttpRequests, FormUtils],
  components: {
    AutoConfirmError,
  },
  data: function () {
    return {
      cruise: false,
      booking: {
        departureLocation: {
          name: "",
        },
        arrivalLocation: {
          name: "",
        },
      },
      session: {},
      manualResevation: false,
      //Translatables
      transTitle: "",
      transSubmit: "",
      transBookingType: "",
      transDepartureDock: "",
      transArrivalDock: "",
      transDateAndTime: "",
      transAt: "",
      transPassengersC: "",
      transCruisePassensers: "",
      transAdults: "",
      transChildren: "",
      transtotalPrice: "",
      transPhoneNumber: "",
      transDuration: "",
      transDurationText: "",
      transBoatType: "",
      transBoatTypeText: "",
    };
  },
  beforeCreate: function () {
    // Prefill values
    this.booking = this.$store.getters.getBooking;
    this.session = this.$store.getters.getBookingSession;
  },
  created: function () {
    var booking = this.$store.getters.getBooking;
    var valid = false;
    switch (booking.bookingType) {
      case "TAXI_RIDE":
        this.cruise = false;
        valid = this.isTaxiRideSessionValid();
        break;
      case "CRUISE":
        this.cruise = true;
        valid = this.isCruiseSessionValid();
        break;
    }

    // Go back to home if validation is not valid
    if (!valid) {
      this.$router.push("/");
    } else {
      // Update
      this.booking = this.$store.getters.getBooking;
      this.session = this.$store.getters.getBookingSession;
      // Check if reservation can only be made manually
      if (!this.session.autoConfirm) {
        this.manualResevation = true;
      } else {
        this.manualResevation = false;
      }
    }
  },
  mounted() {
    this.updateLocale();
    this.$root.$on("change-locale", () => {
      this.updateLocale();
    });
  },
  methods: {
    updateLocale() {
      this.transTitle = this.$gettext("yourBooking");
      this.transSubmit = this.$gettext("checkAvailability");
      this.transDepartureDock = this.$gettext("departureLocationC");
      this.transArrivalDock = this.$gettext("arrivalLocationC");
      this.transDateAndTime = this.$gettext("dateAndTime");
      this.transAt = this.$gettext("at");
      this.transPassengersC = this.$gettext("people");
      this.transtotalPrice = this.$gettext("price");
      this.transPhoneNumber = this.$gettext("phoneNumber");
      this.transDuration = this.$gettext("duration");
      this.transDurationText = this.getDuration();
      this.transBoatType = this.$gettext("boatType");
      this.transBoatTypeText = this.getBoatType();

      switch (this.$store.getters.getbookingtype) {
        case "TAXI_RIDE":
          this.transBookingType = this.$gettext("taxiRide");
          break;
        case "CRUISE":
          this.transBookingType = this.$gettext("roundTrip");
          break;
      }
    },
    getDate() {
      let localMoment = {};
      switch (Vue.config.language) {
        case "nl_NL":
          localMoment = moment.locale("nl");
          break;
        case "en_US":
          localMoment = moment.locale("en");
          break;
      }
      return this.parseDateTime(
        moment(this.booking.dateTime, "DD/MM/YYYY"),
        "dddd D MMMM YYYY"
      );
    },
    getTime() {
      return this.booking.hours + ":" + this.booking.minutes;
    },
    getDuration() {
      return this.booking.duration + " " + this.$gettext("minute");
    },
    getBoatType() {
      switch (this.booking.boatType) {
        case this.$store.state.static.cruiseClassic:
          return this.$gettext("classic");
        case this.$store.state.static.cruiseFast:
          return this.$gettext("fast");
      }
    },
    parseDateTime(momentObj, format) {
      if (momentObj.isValid()) {
        return momentObj.format(format);
      } else {
        return "";
      }
    },
    updateBooking() {
      // Create booking with updated info
      this.createBooking().then((response) => {
        this.$store.commit("updateBookingSession", response);
        this.booking = this.$store.getters.getBooking;
        this.session = this.$store.getters.getBookingSession;
        this.$emit("update-booking-view");
      });
    },
    backToHome() {
      if (this.$store.state.bookingSession.sessionKey != "") {
        this.cancelBooking();
      }
      this.$ga.event({
        eventCategory: "Reservation",
        eventAction: "click",
        eventLabel: "Back to reservation form",
      });
      this.$router.push("/");
    },
  },
};
</script>