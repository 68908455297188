<template>
  	<div v-show="errorState" class="col-xs-12 content-box" >
        <p class="alert warning" v-html="getMessage()"></p>
    </div>
</template>

<script>
export default {
	props: {
		showError: {
		  type: Boolean,
		  default: false
		}
	},
	computed: {
		errorState() {
	  		return this.$store.state.error.active;
		}
	},
	mounted() {
		this.getMessage();
		this.$root.$on("change-locale", () => {
		  this.getMessage();
		}); 
		this.$root.$on("switch-tab", () => {
	      this.getMessage();
	    });
	},
	methods: {
	    getMessage(){
	    	if(this.$store.state.error.active && this.showError){
	    		let message = "";
				switch(this.$store.state.error.errorKey){
					case "PreCheckTimeTooShortTermError":
						let preMessage = this.$gettext("PreCheckTimeTooShortTermError")
						let errorData = this.$store.state.error.data
						message = this.$gettextInterpolate(preMessage, {hours: errorData.bookingHorizon});
						this.$ga.event({
							eventCategory: 'Reservation',
							eventAction: 'error',
							eventLabel: `Same day booking within ${errorData.bookingHorizon} hours`
						});
					break;
					default: 
						message = this.$gettext(this.$store.state.error.errorKey);
					break;
				}
				return message;
	    	}else{
	    		return "";
	    	}
	    }
	}
};
</script>

<style lang="scss">
#planner-container{
	.content-box{
		p.warning{
			margin-top: 0px;
		}
	}
}
</style>
