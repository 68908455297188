import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as VueGoogleMaps from "vue2-google-maps";
import VueGeolocation from 'vue-browser-geolocation';
import GetTextPlugin from 'vue-gettext'
import translations from './translations.json'
import './assets/stylesheets/scss/style.scss';

import VueAnalytics from 'vue-analytics'

Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_ANALYTICS_ID,
  router, 
  disabled: () => new Promise(resolve => {
    // All tracking events will be recorded locally and will only be send when this promise resolves to false.
    // This way, tracking events don't get lost if the cookie consent pop-up is shown (and tracking is initially disabled).
    // If cookie consent is not given, this promise will simply never resolve.

    if (window.cookiesAccepted) {
      resolve(false);
    }

    window.addEventListener('cookiesAcceptedChanged', function() {
      if (window.cookiesAccepted) {
        resolve(false);
      }
    }, false);
  }),
  debug:{
  	enabled: process.env.NODE_ENV === "development",
  	sendHitTask: process.env.NODE_ENV === "production"
  }
})

Vue.use(GetTextPlugin, {
    availableLanguages: {
      en_US: 'American English',
      nl_NL: 'Nederlands'
    },
    defaultLanguage: 'nl_NL',
    translations: translations
  }
)
Vue.use(store)
Vue.use(VueGeolocation)
Vue.use(VueGoogleMaps, {
	load: {
		key: 'AIzaSyBblZVAze6_nACqouN5piqDTEyQdJizhYw',
		libraries: ['places', 'geometry'],
		language: 'nl',
		region: 'NL',
		v: 'quarterly'
	}
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#watertaxi-reservation-app')
