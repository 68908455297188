<template>
  <div>
	
  <div class="box">

    <div class="row" v-show="sessionSuccess">
      <div class="col-xs-12 title-content-box">
        <h4>{{ transBookingCompleted }}</h4>
      </div>
      <div class="col-xs-12 content-box">
        <p class="timer">{{ transBookingConfirmation }}</p>
      </div>
      <button class="btn btn-primary anchor-to-bottom" @click="closeBooking()">{{ transClose }}</button>
    </div>

    <div class="row" v-show="!sessionSuccess">

      <div class="col-xs-12 title-content-box">
        <h4 v-show="openSession">{{ transInsertDetails }}</h4>
        <h4 v-show="!openSession">{{ transTimerEnded }}</h4>
      </div>

      <div class="col-xs-12 content-box">
        
        <p class="timer" v-show="openSession && showTimer">
          <span>{{ timer }}</span>
          {{ transTimer }}
        </p>

        <p class="timer" v-show="!openSession">
          {{ transTimerDescription }}
        </p>

        <!-- Session Form - First name -->
        <TextInput 
          ref="session_firstName"
          v-show="openSession"
          label="firstName"
          inputName="firstName"
          placeholder="fillIn"
          storeName="setFirstname"
        ></TextInput>

        <!-- Session Form - Last name -->
        <TextInput
          ref="session_lastName"
          v-show="openSession"
          label="lastName"
          inputName="lastName"
          placeholder="fillIn"
          storeName="setLastname"
        ></TextInput>

        <!-- Session Form - Email -->
        <TextInput
          ref="session_email"
          v-show="openSession"
          label="email"
          inputName="email"
          placeholder="fillIn"
          storeName="setEmail"
        ></TextInput>

        <!-- Session Form - Phonenumber -->
        <TextInput
          ref="session_phone"
          v-show="openSession"
          label="phone"
          inputName="phone"
          placeholder="fillIn"
          storeName="setPhone"
        ></TextInput>

        <div class="form-group checkbox-group" v-show="openSession">
          <input type="checkbox" name="termsConditions" id="termsConditions" v-model="terms" />
            <label for="termsConditions"></label>
              <span @click="toggleTerms($event)">{{ transAgreeWith }} 
              <a :href="transTermsConditionsPdf" target="_blank">{{ transTermsConditions }}</a></br>
              {{transAnd}}
              <a :href="transPrivacyPolicyPdf" target="_blank">{{ transPrivacyPolicy }}</a>
            </span>
          <p v-show="termsError" class="alert error">{{ transErrorMessage }}</p>
        </div>
      </div>

      <!-- Session Form - Submit -->
      <input
        v-show="openSession"
        type="submit"
        class="btn btn-primary anchor-to-bottom"
        :value="transConfirmBooking"
        @click="sendConfirmBooking()"
      >

      <!-- Session Form - Resend booking -->
      <input
        v-show="!openSession"
        type="submit"
        class="btn btn-primary anchor-to-bottom"
        :value="transResendBooking"
        @click="resendBooking()"
      >

    </div>
  </div>
	 
  </div>
</template>

<script>
import $ from "jquery";
import HttpRequests from "@/components/utilComponents/HttpRequests";
import TextInput from "@/components/formComponents/TextInput";
export default {
  components: {
    TextInput
  },
  mixins: [HttpRequests],
  data: function() {
    return {
      intervalTimer: {},
      seconds: 5,
      timer: "00:00",
      openSession: true,
      terms: false,
      termsError: false,
      sessionSuccess: false,
      showTimer: true,
      //Translatables
      transBookingCompleted: "",
      transBookingConfirmation: "",
      transClose: "",
      transInsertDetails: "",
      transTimerEnded: "",
      transTimer: "",
      transTimerDescription: "",
      transAgreeWith: "",
      transTermsConditions: "",
      transConfirmBooking: "",
      transResendBooking: "",
      transErrorMessage: "",
      transTermsConditionsPdf: "",
      transPrivacyPolicy: "",
      transPrivacyPolicyPdf: "",
      transAnd: ""
      
    };
  },
  mounted: function(){
    this.resetTimer();
    this.updateLocale();
    this.$root.$on("change-locale", () => {
      this.updateLocale();
    });
    this.$root.$on("refresh-contact-form", () => {
      this.resetTimer();
    });
  },
  methods: {
    updateLocale(){
      this.transBookingCompleted = this.$gettext("bookingCompleted");
      this.transBookingConfirmation = this.$gettext("bookingConfirmation");
      this.transClose = this.$gettext("close");
      this.transInsertDetails = this.$gettext("insertYourDetails");
      this.transTimerEnded = this.$gettext("timerEnded");
      this.transTimer = this.$gettext("timer");
      this.transTimerDescription = this.$gettext("timerEndedDescription");
      this.transAgreeWith = this.$gettext("agreeWith");
      this.transTermsConditions = this.$gettext("termsConditions");
      this.transConfirmBooking = this.$gettext("submitBooking");
      this.transResendBooking = this.$gettext("checkAvailabilityAgain");
      this.transErrorMessage = this.$gettext("termsError");
      this.transTermsConditionsPdf = process.env.VUE_APP_PUBLIC_URL + this.$gettext("termsConditionsUrl");
      this.transPrivacyPolicy = this.$gettext("privacyPolicy");
      this.transPrivacyPolicyPdf = process.env.VUE_APP_PUBLIC_URL + this.$gettext("privacyPolicyUrl");
      this.transAnd = this.$gettext("and");
    },
    resetTimer(){
      this.openSession = true;
      clearInterval(this.intervalTimer);
      let session = this.$store.getters.getBookingSession;
      if(session.autoConfirm){
        this.showTimer = true;
        this.seconds = session.timeSlotReservedInSeconds;
        this.countdown();
      }else{
        this.showTimer = false;
      }
    },
    resendBooking(){
      this.$ga.event({
        eventCategory: 'ReservationConfirmation',
        eventAction: "click",
        eventLabel: "User resend booking"
      });
      this.retrieveBooking().then(response => {
        this.$ga.event({
          eventCategory: 'ReservationConfirmation',
          eventAction: "response",
          eventLabel: "Resend booking is successful"
        });
        this.$store.commit("updateBookingSession", response);
        this.seconds = self.$store.getters.getBookingSession.timeSlotReservedInSeconds;
        this.openSession = true;
        this.countdown();
      }).catch(error => {
        let response = error.response;
        this.$ga.event({
          eventCategory: 'ReservationConfirmation',
          eventAction: "response",
          eventLabel: "Resend booking failed"
        });
        this.$store.commit("updateBookingSession", response);
        this.$emit('close-booking-view');
        this.openSession = false;
      });      
    },
    sendConfirmBooking(){      
      this.resetValidations();
      this.$store.commit("setTerms", {
        terms: this.terms
      });

      this.confirmBooking().then(response => {
        this.$ga.event({
          eventCategory: 'ReservationConfirmation',
          eventAction: "confirmed",
          eventLabel: "Booking confirmed and send"
        });
        this.sessionSuccess = true;
        this.$store.commit("setAnalyticsPageStep", 6);
      }).catch(error => {
        let response = error.response;
        this.$ga.event({
            eventCategory: 'ReservationConfirmation',
            eventAction: "response",
            eventLabel: "Contact form invalid"
          });
          // Custom Error (Phone), cause the API is being weird and not consistant, so hacky way for now
          if(response.data.message == "The phone number was never supplied."){
            this.$refs["session_phone"].setError(true);
            this.$refs["session_phone"].setErrorMessage("any.empty");
          }else{
            let errors = response.data.errors
            for(var i = 0; i < errors.length; i++){
              if(errors[i].field == "agreedToTermsConditions"){
                this.termsError = true;
              }else{
                this.$refs["session_"+errors[i].field].setError(true);
                this.$refs["session_"+errors[i].field].setErrorMessage(errors[i].types[0]);
              }
            }
          }
      });
    },
    resetValidations(){
      this.$refs["session_firstName"].setError(false);
      this.$refs["session_lastName"].setError(false);
      this.$refs["session_phone"].setError(false);
      this.$refs["session_email"].setError(false);
      this.termsError = false;
    },
    closeBooking() {
      clearInterval(this.intervalTimer);
      this.terms = false;
      this.sessionSuccess = false;
      this.$store.commit("resetEverything", {});
      this.$router.push('/');
    },
    toggleTerms(){
      // TODO: Using jquery, use the vue solution
      $(event.target).parent().find("input").trigger("click");
    },
    // Timer
    countdown() {
      this.intervalTimer = setInterval(() =>{
        // Clear interval
        if(this.seconds < 0) {
          this.$ga.event({
            eventCategory: 'ReservationConfirmation',
            eventAction: "timer",
            eventLabel: "Session timer ended"
          });
          this.$store.commit("setAnalyticsPageStep", 5);
          clearInterval(this.intervalTimer);
          return;
        }else{
          this.seconds--;
          this.updateTimer();
        }
      }, 1000);
    },
    updateTimer(){
      let secondsLeft = this.seconds;
      if(secondsLeft >= 0){
        let minutes = Math.floor((secondsLeft % 3600) / 60);
        let seconds = secondsLeft % 60;
        this.timer = this.leadingZero(minutes) + ":" + this.leadingZero(seconds);
      }else{
        this.openSession = false;
      }
    },
    leadingZero(numb){
      if(numb < 10){
        return "0" + numb;
      }else{
        return numb;
      }
    }
  }
};
</script>